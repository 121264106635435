import axios from 'axios';

class AdSenseService {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
    this.authStatus = new Map();
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // Configurar interceptores
    this.setupInterceptors();
    // Cargar estados guardados
    this.loadSavedStates();
  }

  setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );
  }

  loadSavedStates() {
    try {
      const savedStates = localStorage.getItem('adsense_auth_states');
      if (savedStates) {
        const states = JSON.parse(savedStates);
        Object.entries(states).forEach(([accountNumber, status]) => {
          this.authStatus.set(accountNumber, status);
        });
      }
    } catch (error) {
      console.error('Error loading saved states:', error);
    }
  }

  async getAuthUrl(accountNumber) {
    try {
      const response = await this.axiosInstance.get(`/api/adsense/auth-url/${accountNumber}`);
      return response.data.url;
    } catch (error) {
      console.error('Error getting auth URL:', error);
      throw new Error('No se pudo obtener la URL de autenticación');
    }
  }

  async handleAuthCode(accountNumber, code) {
    try {
      const response = await this.axiosInstance.post(`/api/adsense/auth`, {
        accountNumber,
        code
      });
      
      if (response.data.success) {
        this.authStatus.set(accountNumber, true);
        this.saveAuthStates();
      }
      
      return response.data;
    } catch (error) {
      console.error('Error handling auth code:', error);
      throw new Error('Error en el proceso de autenticación');
    }
  }

  async getAccountIncome(accountNumber, dateRange = { start: '7daysAgo', end: 'today' }) {
    try {
      if (!this.isAuthenticated(accountNumber)) {
        throw new Error('Cuenta no autenticada');
      }

      const response = await this.axiosInstance.get(`/api/adsense/income/${accountNumber}`, {
        params: {
          startDate: dateRange.start,
          endDate: dateRange.end
        }
      });

      return this.processIncomeData(response.data);
    } catch (error) {
      console.error('Error getting account income:', error);
      throw new Error('Error al obtener datos de ingresos');
    }
  }

  async getAllAccountsIncome(dateRange = { start: '7daysAgo', end: 'today' }) {
    try {
      const response = await this.axiosInstance.get(`/api/adsense/income`, {
        params: {
          startDate: dateRange.start,
          endDate: dateRange.end
        }
      });

      return this.processAllAccountsData(response.data);
    } catch (error) {
      console.error('Error getting all accounts income:', error);
      throw new Error('Error al obtener datos de todas las cuentas');
    }
  }

  async validateCredentials(accountNumber) {
    try {
      const response = await this.axiosInstance.get(`/api/adsense/validate/${accountNumber}`);
      const isValid = response.data.isValid;
      
      this.authStatus.set(accountNumber, isValid);
      this.saveAuthStates();
      
      return isValid;
    } catch (error) {
      console.error('Error validating credentials:', error);
      return false;
    }
  }

  async revokeAccess(accountNumber) {
    try {
      await this.axiosInstance.post(`/api/adsense/revoke/${accountNumber}`);
      this.authStatus.delete(accountNumber);
      this.saveAuthStates();
      return true;
    } catch (error) {
      console.error('Error revoking access:', error);
      throw new Error('Error al revocar acceso');
    }
  }

  async getDetailedStats(accountNumber, dateRange = { start: '7daysAgo', end: 'today' }) {
    try {
      if (!this.isAuthenticated(accountNumber)) {
        throw new Error('Cuenta no autenticada');
      }

      const response = await this.axiosInstance.get(`/api/adsense/stats/${accountNumber}`, {
        params: {
          startDate: dateRange.start,
          endDate: dateRange.end
        }
      });

      return this.processDetailedStats(response.data);
    } catch (error) {
      console.error('Error getting detailed stats:', error);
      throw new Error('Error al obtener estadísticas detalladas');
    }
  }

  isAuthenticated(accountNumber) {
    return this.authStatus.get(accountNumber) === true;
  }

  saveAuthStates() {
    const states = {};
    this.authStatus.forEach((value, key) => {
      states[key] = value;
    });
    localStorage.setItem('adsense_auth_states', JSON.stringify(states));
  }

  processIncomeData(data) {
    return {
      earnings: parseFloat(data.earnings || 0),
      pageViews: parseInt(data.pageViews || 0),
      clicks: parseInt(data.clicks || 0),
      ctr: parseFloat(data.ctr || 0),
      rpm: parseFloat(data.rpm || 0)
    };
  }

  processAllAccountsData(data) {
    return data.map(account => ({
      accountNumber: account.accountNumber,
      data: this.processIncomeData(account.data)
    }));
  }

  processDetailedStats(data) {
    return data.map(stat => ({
      date: stat.date,
      url: stat.url,
      earnings: parseFloat(stat.earnings || 0),
      pageViews: parseInt(stat.pageViews || 0),
      clicks: parseInt(stat.clicks || 0),
      rpm: parseFloat(stat.rpm || 0),
      impressions: parseInt(stat.impressions || 0),
      ctr: parseFloat(stat.ctr || 0)
    }));
  }

  async getAccountsStatus() {
    const status = {};
    for (let i = 1; i <= 4; i++) {
      status[i] = {
        isAuthenticated: this.isAuthenticated(i),
        lastCheck: localStorage.getItem(`adsense_last_check_${i}`) || null
      };
    }
    return status;
  }

  updateLastCheck(accountNumber) {
    const now = new Date().toISOString();
    localStorage.setItem(`adsense_last_check_${accountNumber}`, now);
  }
}

// Crear y exportar la instancia
const adsenseService = new AdSenseService();
export default adsenseService;