import React, { useState, useEffect } from 'react';
import './ApiConfig.css';

const ApiConfig = () => {
  const [showClickstarKey, setShowClickstarKey] = useState(false);
  const [clickstarKey, setClickstarKey] = useState('');
  const [adsenseAccounts, setAdsenseAccounts] = useState([]);
  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [newAccount, setNewAccount] = useState({
    name: '',
    clientId: '',
    clientSecret: '',
    accountId: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [testResults, setTestResults] = useState({});

  // Cargar cuentas al iniciar
  const loadAdsenseAccounts = async () => {
    try {
      setLoading(true);
      const response = await fetch('http://localhost:3001/api/adsense/accounts');
      if (!response.ok) {
        const errorData = await response.text();
        console.error('Error response:', errorData);
        throw new Error('Error al cargar las cuentas');
      }
      const accounts = await response.json();
      console.log('Cuentas cargadas:', accounts);
      setAdsenseAccounts(accounts);
    } catch (error) {
      console.error('Error al cargar cuentas:', error);
      setError('Error al cargar las cuentas de AdSense');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAdsenseAccounts();
  }, []);

  // Función para desconectar cuenta
  const handleDisconnectAccount = async (accountId) => {
    try {
      setError(null);
      const response = await fetch(`http://localhost:3001/api/adsense/accounts/${accountId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Error al desconectar la cuenta');
      }

      setSuccess('Cuenta desconectada correctamente');
      await loadAdsenseAccounts();
    } catch (error) {
      console.error('Error al desconectar cuenta:', error);
      setError(`Error al desconectar la cuenta: ${error.message}`);
    }
  };

  // Añadir nueva cuenta
  const handleAddAccount = async () => {
    try {
      setLoading(true);
      setError(null);
      
      if (!newAccount.clientId || !newAccount.clientSecret || !newAccount.accountId || !newAccount.name) {
        throw new Error('Todos los campos son requeridos');
      }

      const response = await fetch('http://localhost:3001/api/adsense/accounts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: newAccount.accountId,
          name: newAccount.name,  // Este es el nombre que el usuario introduce
          clientId: newAccount.clientId,
          clientSecret: newAccount.clientSecret
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Error al añadir la cuenta');
      }

      setSuccess('Cuenta añadida correctamente');
      setNewAccount({ name: '', clientId: '', clientSecret: '', accountId: '' });
      await loadAdsenseAccounts();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Iniciar proceso de autenticación
  const handleAuthenticateAccount = async (accountId) => {
    try {
      setError(null);
      setCurrentAccountId(accountId);
      const response = await fetch(`http://localhost:3001/api/adsense/auth/${accountId}`);
      
      if (!response.ok) {
        throw new Error('Error al obtener URL de autenticación');
      }
      
      const data = await response.json();
      
      if (data.url) {
        window.open(data.url, 'auth', 'width=600,height=600');
        setAuthDialogOpen(true);
        setAuthCode(''); // Limpiar código anterior
      } else {
        throw new Error('URL de autenticación no recibida');
      }
    } catch (error) {
      console.error('Error al iniciar autenticación:', error);
      setError(error.message);
      setCurrentAccountId(null);
    }
  };

  // Probar conexión
  const handleTestConnection = async (accountId) => {
    try {
      setError(null);
      setTestResults(prev => ({ ...prev, [accountId]: 'testing' }));

      const response = await fetch(`http://localhost:3001/api/adsense/test/${accountId}`);
      const data = await response.json();

      if (data.success) {
        setTestResults(prev => ({ ...prev, [accountId]: 'success' }));
        setSuccess(`Conexión exitosa para la cuenta ${accountId}`);
      } else {
        setTestResults(prev => ({ ...prev, [accountId]: 'error' }));
        throw new Error(data.error || 'Error al probar la conexión');
      }
    } catch (error) {
      console.error('Error al probar conexión:', error);
      setError(`Error al probar la conexión: ${error.message}`);
      setTestResults(prev => ({ ...prev, [accountId]: 'error' }));
    }
  };

  // Procesar código de autenticación
  const handleAuthSubmit = async () => {
    try {
      if (!authCode || !currentAccountId) {
        throw new Error('Código de autenticación o ID de cuenta no válido');
      }

      const response = await fetch('http://localhost:3001/api/adsense/auth/callback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: authCode,
          accountId: currentAccountId
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error en la autenticación');
      }

      setSuccess('Cuenta autenticada correctamente');
      setAuthDialogOpen(false);
      setAuthCode('');
      await loadAdsenseAccounts();
      
      // Probar la conexión automáticamente después de autenticar
      await handleTestConnection(currentAccountId);
    } catch (error) {
      console.error('Error en autenticación:', error);
      setError('Error en la autenticación: ' + error.message);
    } finally {
      setCurrentAccountId(null);
    }
  };

  return (
    <div className="container">
      {/* Pestañas */}
      <div className="tabs">
        <button className="tab active">ADSENSE</button>
        <button className="tab">CLICKSTAR</button>
      </div>

      {/* Formulario para añadir cuenta */}
      <div className="add-account-section">
        <h3>Añadir Nueva Cuenta AdSense</h3>
        <div className="form-group">
          <input
            type="text"
            placeholder="Nombre de la cuenta"
            value={newAccount.name}
            onChange={(e) => setNewAccount({...newAccount, name: e.target.value})}
          />
          <input
            type="text"
            placeholder="Client ID"
            value={newAccount.clientId}
            onChange={(e) => setNewAccount({...newAccount, clientId: e.target.value})}
          />
          <input
            type="password"
            placeholder="Client Secret"
            value={newAccount.clientSecret}
            onChange={(e) => setNewAccount({...newAccount, clientSecret: e.target.value})}
          />
          <input
            type="text"
            placeholder="Account ID"
            value={newAccount.accountId}
            onChange={(e) => setNewAccount({...newAccount, accountId: e.target.value})}
          />
          <button onClick={handleAddAccount} disabled={loading} className="add-button">
            + AÑADIR CUENTA
          </button>
        </div>
      </div>

      {/* Lista de cuentas */}
      <div className="accounts-section">
        <h3>Cuentas Configuradas</h3>
        {adsenseAccounts.map(account => (
          <div key={account.id} className="account-card">
            <div className="account-info">
              <h4>{account.name}</h4> {/* Aquí solo usamos el nombre proporcionado */}
              <p>ID: {account.id}</p>
              <p className={`status ${account.status}`}>
                {account.status === 'active' ? 'Activa' : 'No autenticada'}
              </p>
            </div>
            <div className="account-actions">
              <button 
                className="auth-button"
                onClick={() => handleAuthenticateAccount(account.id)}
              >
                AUTENTICAR
              </button>
              <button 
                className="test-button"
                onClick={() => handleTestConnection(account.id)}
                disabled={testResults[account.id] === 'testing'}
              >
                {testResults[account.id] === 'testing' ? 'PROBANDO...' : 'PROBAR CONEXIÓN'}
              </button>
              <button 
                className="disconnect-button"
                onClick={() => handleDisconnectAccount(account.id)}
                title="Desconectar esta cuenta de AdSense"
              >
                DESCONECTAR CUENTA
              </button>
            </div>
            {testResults[account.id] && (
              <div className={`test-result ${testResults[account.id]}`}>
                {testResults[account.id] === 'success' ? '✓ Conexión exitosa' : '✗ Error de conexión'}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Diálogo de autenticación */}
      {authDialogOpen && (
        <div className="auth-dialog">
          <div className="auth-dialog-content">
            <h3>Autenticación AdSense</h3>
            <p>Introduce el código de autorización:</p>
            <input
              type="text"
              value={authCode}
              onChange={(e) => setAuthCode(e.target.value)}
              placeholder="Código de autorización"
            />
            <div style={{ marginTop: '20px', display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
              <button onClick={() => {
                setAuthDialogOpen(false);
                setAuthCode('');
                setCurrentAccountId(null);
              }}>
                Cancelar
              </button>
              <button onClick={handleAuthSubmit} disabled={!authCode}>
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Mensajes de error/éxito */}
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      {success && (
        <div className="success-message">
          {success}
        </div>
      )}
    </div>
  );
};

export default ApiConfig;