import React, { useState } from 'react';
import {
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  Box, 
  Avatar, 
  Drawer, 
  List, 
  ListItem, 
  Divider, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Button, 
  Link, 
  useTheme, 
  useMediaQuery, 
  InputAdornment,
  Menu
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import BuildIcon from '@mui/icons-material/Build';
import TuneIcon from '@mui/icons-material/Tune';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import FlagIcon from '@mui/icons-material/Flag';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/system';
import logo from '../assets/logo.png';
import avatar from '../assets/avatar.jpg';

// Definición de colores y constantes
const contentStatusColors = {
  'Redactando': '#FFF3CD',
  'Completado': '#D4EDDA',
  'Pausado': '#FFE5D0'
};

const activeFilterColor = '#E7F7EE';

const priorityColors = {
  'alta': '#F44336',    
  'media': '#FFC107',   
  'baja': '#4CAF50'     
};

const languageOptions = [
  'Español',
  'Inglés',
  'Francés',
  'Alemán',
  'Italiano',
  'Portugués',
  'Japonés',
  'Chino'
];

// Styled components
const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#1565C0',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const Logo = styled('img')(({ theme }) => ({
  height: 45,
  width: 'auto',
  marginRight: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    height: 35,
    marginRight: theme.spacing(1),
  },
}));

const SearchWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '160px',
  marginLeft: 'auto',
  marginRight: theme.spacing(2),
}));

const InfoButton = styled(Box)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor,
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: '150px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  transition: 'transform 0.2s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
}));

const InfoButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  justifyContent: 'center',
  flexWrap: 'wrap',
  backgroundColor: '#fff',
  borderBottom: '1px solid #e0e0e0',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const FilterSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .sectionTitle': {
    color: '#1565C0',
    fontWeight: 600,
    fontSize: '0.875rem',
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    letterSpacing: '0.5px'
  }
}));

const FilterGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '& .fullWidth': {
    gridColumn: '1 / -1'
  }
}));

const ActionButtons = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: '#F8FAFC',
  padding: theme.spacing(2),
  borderTop: '1px solid #E2E8F0',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: 'auto',
  '& .MuiButton-root': {
    borderRadius: '8px',
    padding: '10px 16px',
    fontWeight: 600
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'white',
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
  },
}));

const OptionsButton = styled(IconButton)(({ theme }) => ({
  color: '#1565C0',
  backgroundColor: '#BBE3FA',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  padding: '8px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#93D3F8',
    transform: 'scale(1.05)',
  },
  '&.active': {
    backgroundColor: '#93D3F8',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: 'white',
  marginLeft: theme.spacing(1),
  padding: '6px 16px',
  transition: 'all 0.3s ease',
  position: 'relative',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  '&.active': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '3px',
      backgroundColor: '#BBE3FA',
      borderRadius: '2px 2px 0 0',
    },
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

function Header({
  filter,
  setFilter,
  adsenseFilter,
  setAdsenseFilter,
  clickstarFilter,
  setClickstarFilter,
  footprintsFilter,
  setFootprintsFilter,
  hostingFilter,
  setHostingFilter,
  topicFilter,
  setTopicFilter,
  contentStatusFilter,
  setContentStatusFilter,
  expirationOrder,
  setExpirationOrder,
  languageFilter,
  setLanguageFilter,
  priorityFilter,
  setPriorityFilter,
  addedDateOrder,
  setAddedDateOrder,
  emailGSCFilter,
  setEmailGSCFilter,
  emailAdsenseFilter,
  setEmailAdsenseFilter,
  addedToBWTFilter,
  setAddedToBWTFilter,
  addedToManageWPFilter,
  setAddedToManageWPFilter,
  nichoFilter,
  setNichoFilter,
  emailGSCOptions,
  emailAdsenseOptions,
  expirationDateRange,
  setExpirationDateRange,
  addedDateRange,
  setAddedDateRange,
  hostingOptions,
  topicOptions,
  onAddWebClick,
  selectAllWidgets,
  clearSelection,
  deleteSelectedWidgets,
  onExport,
  onImport,
  fileInputRef,
  totalWebs,
  filteredWebs,
  totalRenewalCost,
  totalNichoRenewalCost,
  currentMonthRenewalCost,
  currentMonthNichoRenewalCost,
  currentMonthName,
  activeMenu,
  onMenuClick
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtersActive, setFiltersActive] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Estados para los filtros locales
  const [nicho, setNicho] = useState('');
  const [adsense, setAdsense] = useState('');
  const [clickstar, setClickstar] = useState('');
  const [footprints, setFootprints] = useState('');
  const [hosting, setHosting] = useState('');
  const [topic, setTopic] = useState('');
  const [contentStatus, setContentStatus] = useState('');
  const [language, setLanguage] = useState('');
  const [priority, setPriority] = useState('');
  const [emailGSC, setEmailGSC] = useState('');
  const [emailAdsense, setEmailAdsense] = useState('');
  const [addedToBWT, setAddedToBWT] = useState('');
  const [addedToManageWP, setAddedToManageWP] = useState('');
  const [localExpirationDateRange, setLocalExpirationDateRange] = useState(expirationDateRange);
  const [localAddedDateRange, setLocalAddedDateRange] = useState(addedDateRange);

const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (menu) => {
    if (onMenuClick) {
      onMenuClick(menu);
      setDrawerOpen(false);
    }
  };

  const handleFiltersClick = () => {
    setFiltersActive(!filtersActive);
    toggleDrawer('filters')();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const toggleDrawer = (drawer) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    if (drawer === 'main') {
      setDrawerOpen(!drawerOpen);
    } else {
      setFiltersDrawerOpen(!filtersDrawerOpen);
      if (!filtersDrawerOpen) {
        setFiltersActive(true);
      } else {
        setFiltersActive(false);
      }
    }
  };

  const applyFilters = () => {
    setAdsenseFilter(adsense);
    setClickstarFilter(clickstar);
    setFootprintsFilter(footprints);
    setHostingFilter(hosting);
    setTopicFilter(topic);
    setContentStatusFilter(contentStatus);
    setLanguageFilter(language);
    setPriorityFilter(priority);
    setEmailGSCFilter(emailGSC);
    setEmailAdsenseFilter(emailAdsense);
    setAddedToBWTFilter(addedToBWT);
    setAddedToManageWPFilter(addedToManageWP);
    setNichoFilter(nicho);
    setExpirationDateRange(localExpirationDateRange);
    setAddedDateRange(localAddedDateRange);
    setFiltersDrawerOpen(false);
    setFiltersActive(false);
  };

  const clearFilters = () => {
    setAdsense('');
    setClickstar('');
    setFootprints('');
    setHosting('');
    setTopic('');
    setContentStatus('');
    setLanguage('');
    setPriority('');
    setFilter('');
    setEmailGSC('');
    setEmailAdsense('');
    setAddedToBWT('');
    setAddedToManageWP('');
    setNicho('');
    setLocalExpirationDateRange({ start: '', end: '' });
    setLocalAddedDateRange({ start: '', end: '' });
    
    setAdsenseFilter('');
    setClickstarFilter('');
    setFootprintsFilter('');
    setHostingFilter('');
    setTopicFilter('');
    setContentStatusFilter('');
    setLanguageFilter('');
    setPriorityFilter('');
    setEmailGSCFilter('');
    setEmailAdsenseFilter('');
    setAddedToBWTFilter('');
    setAddedToManageWPFilter('');
    setNichoFilter('');
    setExpirationDateRange({ start: '', end: '' });
    setAddedDateRange({ start: '', end: '' });
  };

  // Comienzo del renderizado
  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomAppBar position="static">
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={toggleDrawer('main')}
              sx={{ 
                mr: 1,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Logo src={logo} alt="Logo" />

          <Box sx={{ flexGrow: 1 }} />

          {!isMobile && (
            <>
              <NavButton 
                startIcon={<HomeIcon />}
                className={activeMenu === 'inicio' ? 'active' : ''}
                onClick={() => handleMenuClick('inicio')}
              >
                Inicio
              </NavButton>
              <NavButton 
                startIcon={<MonetizationOnIcon />}
                className={activeMenu === 'ingresos' ? 'active' : ''}
                onClick={() => handleMenuClick('ingresos')}
              >
                Dashboard de Ingresos
              </NavButton>
              <NavButton 
                startIcon={<BuildIcon />}
                className={activeMenu === 'herramientas' ? 'active' : ''}
                onClick={() => handleMenuClick('herramientas')}
              >
                Herramientas
              </NavButton>
            </>
          )}

          <SearchWrapper>
            <StyledTextField
              fullWidth
              variant="outlined"
              placeholder="Buscar web..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'white' }} />
                  </InputAdornment>
                ),
              }}
              size={isMobile ? "small" : "medium"}
            />
          </SearchWrapper>

          <OptionsButton
            onClick={handleFiltersClick}
            className={filtersActive ? 'active' : ''}
            size={isMobile ? "small" : "medium"}
          >
            <TuneIcon />
          </OptionsButton>

          <IconButton
            onClick={handleProfileMenuOpen}
            sx={{ 
              p: 0,
              ml: 1,
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
          >
            <Avatar 
              src={avatar} 
              alt="Avatar" 
              sx={{ 
                width: isMobile ? 32 : 40,
                height: isMobile ? 32 : 40,
                border: '2px solid #BBE3FA',
              }}
            />
          </IconButton>
        </Toolbar>
      </CustomAppBar>

      {/* Tarjetas de información actualizadas */}
      <InfoButtonsContainer>
        <InfoButton bgcolor="#B5EAD7">
          <Typography variant="h6" fontWeight="bold">
            {totalWebs}
          </Typography>
          <Typography variant="subtitle2">
            Total en Panel
          </Typography>
        </InfoButton>

        <InfoButton bgcolor="#FFDAC1">
          <Typography variant="h6" fontWeight="bold">
            {filteredWebs}
          </Typography>
          <Typography variant="subtitle2">
            Filtradas
          </Typography>
        </InfoButton>

        <InfoButton bgcolor="#C7CEEA">
          <Typography variant="h6" fontWeight="bold">
            {totalRenewalCost}€
          </Typography>
          <Typography variant="subtitle2">
            Renovación Todas
          </Typography>
        </InfoButton>

        <InfoButton bgcolor="#A8D5E2">
          <Typography variant="h6" fontWeight="bold">
            {totalNichoRenewalCost}€
          </Typography>
          <Typography variant="subtitle2">
            Renovación Nichos
          </Typography>
        </InfoButton>

        <InfoButton bgcolor="#FFB7B2">
          <Typography variant="h6" fontWeight="bold">
            {currentMonthNichoRenewalCost}€
          </Typography>
          <Typography variant="subtitle2">
            Renovación Nichos {currentMonthName}
          </Typography>
        </InfoButton>
      </InfoButtonsContainer>

      {/* Menú de perfil */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        onClick={handleProfileMenuClose}
        PaperProps={{
          elevation: 3,
          sx: {
            mt: 1.5,
            minWidth: 180,
            overflow: 'visible',
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            '& .MuiList-root': {
              py: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => {}} sx={{ py: 1 }}>
          <PersonIcon sx={{ mr: 2, color: '#1565C0' }} />
          Perfil
        </MenuItem>
        <MenuItem onClick={() => handleMenuClick('api-config')} sx={{ py: 1 }}>
          <SettingsIcon sx={{ mr: 2, color: '#1565C0' }} />
          Configuración de APIs
        </MenuItem>
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={handleLogout} sx={{ py: 1 }}>
        <LogoutIcon sx={{ mr: 2, color: '#1565C0' }} />
        Cerrar sesión
      </MenuItem>
      </Menu>

{/* Menú móvil */}
<Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer('main')}
        PaperProps={{
          sx: {
            width: 250,
            p: 2,
            backgroundColor: '#F8FAFC',
          },
        }}
      >
        <List>
          <ListItem>
            <Button 
              fullWidth 
              startIcon={<HomeIcon />}
              sx={{ 
                justifyContent: 'flex-start',
                color: activeMenu === 'inicio' ? '#1565C0' : 'inherit',
                backgroundColor: activeMenu === 'inicio' ? '#BBE3FA' : 'transparent',
                '&:hover': {
                  backgroundColor: '#E3F2FD',
                },
              }}
              onClick={() => handleMenuClick('inicio')}
            >
              Inicio
            </Button>
          </ListItem>
          <ListItem>
            <Button 
              fullWidth 
              startIcon={<MonetizationOnIcon />}
              sx={{ 
                justifyContent: 'flex-start',
                color: activeMenu === 'ingresos' ? '#1565C0' : 'inherit',
                backgroundColor: activeMenu === 'ingresos' ? '#BBE3FA' : 'transparent',
                '&:hover': {
                  backgroundColor: '#E3F2FD',
                },
              }}
              onClick={() => handleMenuClick('ingresos')}
            >
              Dashboard de Ingresos
            </Button>
          </ListItem>
          <ListItem>
            <Button 
              fullWidth 
              startIcon={<BuildIcon />}
              sx={{ 
                justifyContent: 'flex-start',
                color: activeMenu === 'herramientas' ? '#1565C0' : 'inherit',
                backgroundColor: activeMenu === 'herramientas' ? '#BBE3FA' : 'transparent',
                '&:hover': {
                  backgroundColor: '#E3F2FD',
                },
              }}
              onClick={() => handleMenuClick('herramientas')}
            >
              Herramientas
            </Button>
          </ListItem>
        </List>
      </Drawer>

      {/* Drawer de filtros y opciones */}
      <Drawer
        anchor="right"
        open={filtersDrawerOpen}
        onClose={toggleDrawer('filters')}
        PaperProps={{
          sx: {
            width: 400,
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          },
        }}
      >
        <Box sx={{ 
          p: 3, 
          flex: 1, 
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 2 
        }}>
          <Typography variant="h6" sx={{ mb: 3, color: '#1565C0', fontWeight: 600 }}>
            Filtros y Opciones
          </Typography>

          <FilterSection>
            <Typography className="sectionTitle">Filtros de Email</Typography>
            <FilterGrid>
              <FormControl fullWidth size="small">
                <InputLabel>Email GSC</InputLabel>
                <Select
                  value={emailGSC}
                  onChange={(e) => setEmailGSC(e.target.value)}
                  startAdornment={<EmailIcon sx={{ mr: 1, color: 'action.active' }} />}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {emailGSCOptions.map(email => (
                    <MenuItem key={email} value={email}>{email}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>Email Adsense</InputLabel>
                <Select
                  value={emailAdsense}
                  onChange={(e) => setEmailAdsense(e.target.value)}
                  startAdornment={<EmailIcon sx={{ mr: 1, color: 'action.active' }} />}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {emailAdsenseOptions.map(email => (
                    <MenuItem key={email} value={email}>{email}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FilterGrid>
          </FilterSection>

          <FilterSection>
            <Typography className="sectionTitle">Rangos de Fecha</Typography>
            <FilterGrid>
              <Box className="fullWidth">
                <Typography variant="subtitle2" sx={{ mb: 1 }}>Rango de expiración</Typography>
                <Box display="flex" gap={1}>
                  <TextField
                    type="date"
                    size="small"
                    label="Desde"
                    value={localExpirationDateRange.start}
                    onChange={(e) => setLocalExpirationDateRange(prev => ({ ...prev, start: e.target.value }))}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                  <TextField
                    type="date"
                    size="small"
                    label="Hasta"
                    value={localExpirationDateRange.end}
                    onChange={(e) => setLocalExpirationDateRange(prev => ({ ...prev, end: e.target.value }))}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box className="fullWidth">
                <Typography variant="subtitle2" sx={{ mb: 1 }}>Rango de añadido</Typography>
                <Box display="flex" gap={1}>
                  <TextField
                    type="date"
                    size="small"
                    label="Desde"
                    value={localAddedDateRange.start}
                    onChange={(e) => setLocalAddedDateRange(prev => ({ ...prev, start: e.target.value }))}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                  <TextField
                    type="date"
                    size="small"
                    label="Hasta"
                    value={localAddedDateRange.end}
                    onChange={(e) => setLocalAddedDateRange(prev => ({ ...prev, end: e.target.value }))}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Box>
              </Box>
            </FilterGrid>
          </FilterSection>

          <FilterSection>
            <Typography className="sectionTitle">Estados</Typography>
            <FilterGrid>
              <FormControl fullWidth size="small">
                <InputLabel>Estado del Contenido</InputLabel>
                <Select
                  value={contentStatus}
                  onChange={(e) => setContentStatus(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="Redactando" sx={{ backgroundColor: contentStatusColors.Redactando }}>
                    Redactando
                  </MenuItem>
                  <MenuItem value="Completado" sx={{ backgroundColor: contentStatusColors.Completado }}>
                    Completado
                  </MenuItem>
                  <MenuItem value="Pausado" sx={{ backgroundColor: contentStatusColors.Pausado }}>
                    Pausado
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>Adsense</InputLabel>
                <Select
                  value={adsense}
                  onChange={(e) => setAdsense(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="Aprobado">Aprobado</MenuItem>
                  <MenuItem value="Rechazado">Rechazado</MenuItem>
                  <MenuItem value="Enviado">Enviado</MenuItem>
                  <MenuItem value="Sin Enviar">Sin Enviar</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>Clickstar</InputLabel>
                <Select
                  value={clickstar}
                  onChange={(e) => setClickstar(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="Aprobado">Aprobado</MenuItem>
                  <MenuItem value="Rechazado">Rechazado</MenuItem>
                  <MenuItem value="Pausado">Pausado</MenuItem>
                  <MenuItem value="Enviado">Enviado</MenuItem>
                  <MenuItem value="Sin Enviar">Sin Enviar</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>Añadido a BWT</InputLabel>
                <Select
                  value={addedToBWT}
                  onChange={(e) => setAddedToBWT(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="Sí">Sí</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>Añadido a ManageWP</InputLabel>
                <Select
                  value={addedToManageWP}
                  onChange={(e) => setAddedToManageWP(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="Sí">Sí</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>Limpieza de Footprints</InputLabel>
                <Select
                  value={footprints}
                  onChange={(e) => setFootprints(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="Sí">Sí</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
          <InputLabel>Nicho</InputLabel>
          <Select
            value={nicho}
            onChange={(e) => setNicho(e.target.value)}
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="Sí">Sí</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
            </FilterGrid>
          </FilterSection>

          <FilterSection>
            <Typography className="sectionTitle">Configuración</Typography>
            <FilterGrid>
              <FormControl fullWidth size="small">
                <InputLabel>Idioma</InputLabel>
                <Select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {languageOptions.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>Prioridad</InputLabel>
                <Select
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                >
                  <MenuItem value="">Todas</MenuItem>
                  <MenuItem value="alta">
                    <Box sx={{ display: 'flex', alignItems: 'center', color: priorityColors.alta }}>
                      <FlagIcon sx={{ mr: 1 }} />
                      Alta
                    </Box>
                  </MenuItem>
                  <MenuItem value="media">
                    <Box sx={{ display: 'flex', alignItems: 'center', color: priorityColors.media }}>
                      <FlagIcon sx={{ mr: 1 }} />
                      Media
                    </Box>
                  </MenuItem>
                  <MenuItem value="baja">
                    <Box sx={{ display: 'flex', alignItems: 'center', color: priorityColors.baja }}>
                      <FlagIcon sx={{ mr: 1 }} />
                      Baja
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>Hosting</InputLabel>
                <Select
                  value={hosting}
                  onChange={(e) => setHosting(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {hostingOptions.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>Temática</InputLabel>
                <Select
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {topicOptions.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FilterGrid>
          </FilterSection>

          <FilterSection>
            <Typography className="sectionTitle">Importar / Exportar</Typography>
            <FilterGrid>
              <Button
                variant="contained"
                startIcon={<CloudUploadIcon />}
                onClick={() => fileInputRef?.current?.click()}
                fullWidth
                sx={{
                  backgroundColor: '#1565C0',
                  '&:hover': {
                    backgroundColor: '#1976D2',
                  },
                }}
              >
                Exportar .xlsx
              </Button>
              <Button
                variant="contained"
                startIcon={<CloudUploadIcon />}
                onClick={() => fileInputRef.current.click()}
                fullWidth
                sx={{
                  backgroundColor: '#1565C0',
                  '&:hover': {
                    backgroundColor: '#1976D2',
                  },
                }}
              >
                Importar .xlsx
              </Button>
            </FilterGrid>
            <Link 
              href="https://seonico.com/plantilla-importaciones.xlsx" 
              sx={{ 
                display: 'block', 
                textAlign: 'center',
                mt: 1,
                color: '#1565C0',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Descargar plantilla
            </Link>
          </FilterSection>
        </Box>

        <ActionButtons>
          <Button
            variant="contained"
            onClick={applyFilters}
            fullWidth
            sx={{
              backgroundColor: '#1565C0',
              '&:hover': {
                backgroundColor: '#1976D2',
              },
            }}
          >
            Aplicar Filtros
          </Button>
          <Button
            variant="outlined"
            onClick={clearFilters}
            fullWidth
            sx={{
              borderColor: '#1565C0',
              color: '#1565C0',
              '&:hover': {
                borderColor: '#1976D2',
                backgroundColor: 'rgba(25, 118, 210, 0.04)',
              },
            }}
          >
            Limpiar Filtros
          </Button>

          {activeMenu === 'inicio' && (
            <>
              <Divider sx={{ my: 1 }} />
              <Button
                variant="contained"
                onClick={selectAllWidgets}
                fullWidth
                sx={{
                  backgroundColor: '#1565C0',
                  '&:hover': {
                    backgroundColor: '#1976D2',
                  },
                }}
              >
                Seleccionar Todo
              </Button>
              <Button
                variant="outlined"
                onClick={clearSelection}
                fullWidth
                sx={{
                  borderColor: '#1565C0',
                  color: '#1565C0',
                  '&:hover': {
                    borderColor: '#1976D2',
                    backgroundColor: 'rgba(25, 118, 210, 0.04)',
                  },
                }}
              >
                Deseleccionar Todo
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={deleteSelectedWidgets}
                fullWidth
                sx={{
                  '&:hover': {
                    backgroundColor: '#D32F2F',
                  },
                }}
              >
                Eliminar Seleccionados
              </Button>
            </>
          )}
        </ActionButtons>
      </Drawer>
      <input
        type="file"
        accept=".xlsx"
        ref={fileInputRef}
        onChange={onImport}
        style={{ display: 'none' }}
        data-testid="file-input"
      />
    </Box>
  );
}

export default Header;