import * as XLSX from 'xlsx';

/**
 * Convierte cualquier formato de fecha a objeto Date
 * @param {string|Date} date - La fecha a convertir
 * @returns {Date|null} - Objeto Date o null si no es válida
 */
function toDate(date) {
  if (!date) return null;
  
  // Si ya es un objeto Date
  if (date instanceof Date) return date;
  
  // Si es un número (formato Excel)
  const serialDate = parseFloat(date);
  if (!isNaN(serialDate)) {
    return new Date((serialDate - 25569) * 86400 * 1000);
  }
  
  // Si es string con formato dd/mm/yyyy
  if (typeof date === 'string' && date.includes('/')) {
    const [day, month, year] = date.split('/').map(Number);
    return new Date(year, month - 1, day);
  }
  
  // Si es string ISO o cualquier otro formato válido
  const parsedDate = new Date(date);
  return isNaN(parsedDate.getTime()) ? null : parsedDate;
}

/**
 * Formatea una fecha a ISO string (para almacenamiento y ordenación)
 * @param {Date|string} date - La fecha a formatear
 * @returns {string} Fecha en formato ISO o string vacío
 */
function toISOString(date) {
  const converted = toDate(date);
  return converted ? converted.toISOString() : '';
}

/**
 * Formatea una fecha al formato dd/mm/yyyy.
 * @param {Date|string} date - La fecha a formatear.
 * @returns {string} Fecha formateada o cadena vacía si no hay fecha.
 */
function formatDate(date) {
  const converted = toDate(date);
  if (!converted) return '';
  
  const day = String(converted.getDate()).padStart(2, '0');
  const month = String(converted.getMonth() + 1).padStart(2, '0');
  const year = converted.getFullYear();
  return `${day}/${month}/${year}`;
}

/**
 * Normaliza un valor eliminando tildes, convirtiendo a minúsculas, y normalizando espacios.
 * @param {string} value - El valor a normalizar.
 * @returns {string} Valor normalizado.
 */
function normalizeValue(value) {
  if (value === null || value === undefined) return '';
  return String(value)
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, ' ');
}

/**
 * Normaliza valores booleanos a 'Sí' o 'No'.
 * @param {string|boolean} value - El valor a normalizar.
 * @returns {string} 'Sí' o 'No'.
 */
function normalizeBooleanValue(value) {
  if (value === null || value === undefined) return 'No';
  const normalizedValue = normalizeValue(value);
  if (['si', 'sí', 'true', 'verdadero', '1', 'yes'].includes(normalizedValue)) {
    return 'Sí';
  }
  if (['no', 'false', 'falso', '0'].includes(normalizedValue)) {
    return 'No';
  }
  return 'No';
}

/**
 * Normaliza el estado de contenido a uno de los valores válidos.
 * @param {string} value - El valor a normalizar.
 * @returns {string} Estado de contenido normalizado.
 */
function normalizeContentStatus(value) {
  const validContentStates = ['Redactando', 'Completado', 'Pausado'];
  const normalizedValue = normalizeValue(value);
  const stateMap = {
    'redactando': 'Redactando',
    'completado': 'Completado',
    'completo': 'Completado',
    'terminado': 'Completado',
    'pausado': 'Pausado',
    'pausa': 'Pausado'
  };
  return stateMap[normalizedValue] || validContentStates[0];
}

/**
 * Normaliza el idioma a uno de los valores válidos.
 * @param {string} value - El valor a normalizar.
 * @returns {string} Idioma normalizado.
 */
function normalizeLanguage(value) {
  if (!value) return '';
  
  const validLanguages = [
    'Español',
    'Inglés',
    'Francés',
    'Alemán',
    'Italiano',
    'Portugués',
    'Japonés',
    'Chino'
  ];

  const languageMap = {
    'espanol': 'Español',
    'spanish': 'Español',
    'ingles': 'Inglés',
    'english': 'Inglés',
    'frances': 'Francés',
    'french': 'Francés',
    'aleman': 'Alemán',
    'german': 'Alemán',
    'italiano': 'Italiano',
    'italian': 'Italiano',
    'portugues': 'Portugués',
    'portuguese': 'Portugués',
    'japones': 'Japonés',
    'japanese': 'Japonés',
    'chino': 'Chino',
    'chinese': 'Chino'
  };

  const normalized = normalizeValue(value);
  return languageMap[normalized] || validLanguages.find(lang => 
    normalizeValue(lang) === normalized
  ) || value;
}

/**
 * Normaliza estados a uno de los valores válidos.
 * @param {string} value - El valor a normalizar.
 * @param {string[]} validStates - Lista de estados válidos.
 * @returns {string} Estado normalizado.
 */
function normalizeState(value, validStates) {
  const normalizedValue = normalizeValue(value);
  const stateMap = validStates.reduce((acc, state) => {
    acc[normalizeValue(state)] = state;
    return acc;
  }, {});
  return stateMap[normalizedValue] || validStates[0];
}

/**
 * Exporta los datos de webs a un archivo .xlsx descargable.
 * @param {Array} webData - Lista de objetos que contienen los datos de las webs.
 */
export function exportWebDataToExcel(webData) {
  const formattedData = webData.map((site) => ({
    'Nombre de dominio': site.url,
    'Email GSC': site.emailGSC || '',
    'Email Adsense': site.emailAdsense || '',
    'Estado de Adsense': site.adsense,
    'Nicho': site.nicho || 'No',
    'Estado de Clickstar': site.clickstar,
    'Limpieza de Footprints': site.footprints,
    'Añadido a BWT': site.addedToBWT,           // Nuevo campo
    'Añadido a ManageWP': site.addedToManageWP, // Nuevo campo
    'Hosting': site.hosting,
    'Temática': site.topic,
    'Estado del Contenido': site.contentStatus,
    'Idioma': site.language || '',
    'Fecha de añadido': formatDate(site.addedDate),
    'Fecha de renovación del dominio': formatDate(site.domainRenewalDate),
    'Precio de renovación del dominio': site.domainRenewalPrice,
    'Prioridad': site.priority || 'media',
    'Notas': site.notes || ''
  }));

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'WebData');
  XLSX.writeFile(workbook, 'nichos-exportados.xlsx');
}

function normalizePriority(value) {
  const priorityMap = {
    'alta': 'alta',
    'high': 'alta',
    'media': 'media',
    'medium': 'media',
    'baja': 'baja',
    'low': 'baja'
  };
  return priorityMap[normalizeValue(value)] || 'media';
}

/**
 * Importa los datos de webs desde un archivo .xlsx.
 */
export function importWebDataFromExcel(file, existingWebsites, updateHostingOptions, updateTopicOptions) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Estados válidos
        const adsenseStates = ['Sin Enviar', 'Enviado', 'Rechazado', 'Aprobado'];
        const clickstarStates = ['Sin Enviar', 'Enviado', 'Rechazado', 'Pausado', 'Aprobado'];

        const websitesData = jsonData
          .filter((row) => !existingWebsites.includes(normalizeValue(row['Nombre de dominio'])))
          .map((row) => {
            const hosting = row['Hosting'] || '';
            const topic = row['Temática'] || '';
            

            // Actualizar opciones
            if (hosting && !updateHostingOptions.current.includes(hosting)) {
              updateHostingOptions.set([...updateHostingOptions.current, hosting]);
            }
            if (topic && !updateTopicOptions.current.includes(topic)) {
              updateTopicOptions.set([...updateTopicOptions.current, topic]);
            }

            // Fechas
            const renewalDate = toDate(row['Fecha de renovación del dominio']);
            const addedDate = toDate(row['Fecha de añadido']) || new Date();

            return {
              id: Math.random().toString(36).substr(2, 9),
              url: row['Nombre de dominio'] || '',
              emailGSC: row['Email GSC'] || '',
              emailAdsense: row['Email Adsense'] || '',
              adsense: normalizeState(row['Estado de Adsense'], adsenseStates),
              clickstar: normalizeState(row['Estado de Clickstar'], clickstarStates),
              footprints: normalizeBooleanValue(row['Limpieza de Footprints']),
              nicho: normalizeBooleanValue(row['Nicho']),
              addedToBWT: normalizeBooleanValue(row['Añadido a BWT']),
              addedToManageWP: normalizeBooleanValue(row['Añadido a ManageWP']),
              hosting: hosting,
              topic: topic,
              contentStatus: normalizeContentStatus(row['Estado del Contenido']),
              language: normalizeLanguage(row['Idioma']),
              addedDate: addedDate.toISOString(),
              domainRenewalDate: renewalDate ? renewalDate.toISOString() : '',
              domainRenewalPrice: parseFloat(row['Precio de renovación del dominio']) || 0,
              priority: normalizePriority(row['Prioridad']),
              notes: row['Notas'] || ''
            };
          });

        resolve(websitesData);
      } catch (error) {
        console.error('Error procesando el archivo:', error);
        reject(error);
      }
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}