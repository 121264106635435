import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

export default function ConfirmDialog({ 
  open, 
  onClose, 
  onConfirm, 
  title = "Confirmar eliminación", 
  content = "¿Estás seguro de que deseas eliminar los elementos seleccionados? Esta acción no se puede deshacer.",
  selectedCount = 0
}) {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: '320px'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        color: 'error.main' 
      }}>
        <WarningIcon color="error" />
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography>{content}</Typography>
        {selectedCount > 0 && (
          <Box sx={{ mt: 2, fontWeight: 'bold' }}>
            <Typography color="error">
              Se eliminarán {selectedCount} elemento{selectedCount !== 1 ? 's' : ''}.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
}