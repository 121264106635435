import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FlagIcon from '@mui/icons-material/Flag';
import EmailIcon from '@mui/icons-material/Email';

// Definición de colores para estados de contenido
const contentStatusColors = {
  'Redactando': '#FFF3CD',
  'Completado': '#D4EDDA',
  'Pausado': '#FFE5D0'
};

// Definición de colores para prioridades
const priorityColors = {
  'alta': '#F44336',    // Rojo
  'media': '#FFC107',   // Amarillo
  'baja': '#4CAF50'     // Verde
};

// Opciones de idiomas predefinidos
const languageOptions = [
  'Español',
  'Inglés',
  'Francés',
  'Alemán',
  'Italiano',
  'Portugués',
  'Japonés',
  'Chino',
  'new'
];

export default function AddWebModal({
  open,
  onClose,
  onAddWeb,
  hostingOptions = [],
  topicOptions = [],
  setHostingOptions,
  setTopicOptions,
}) {
  const [formData, setFormData] = useState({
    url: "",
    emailGSC: "",
    emailAdsense: "",
    hosting: "",
    topic: "",
    adsense: "",
    clickstar: "",
    footprints: "",
    addedToBWT: "",      // Nuevo campo
    addedToManageWP: "", // Nuevo campo
    domainRenewalPrice: 0,
    domainRenewalDate: "",
    contentStatus: "Redactando",
    language: "",
    notes: "",
    priority: "media",
    nicho: "No", // Nuevo campo
  });

  const [newHosting, setNewHosting] = useState("");
  const [newTopic, setNewTopic] = useState("");
  const [newLanguage, setNewLanguage] = useState("");
  const [customLanguages, setCustomLanguages] = useState(() => {
    const savedLanguages = localStorage.getItem("customLanguages");
    return savedLanguages ? JSON.parse(savedLanguages) : [];
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const {
      url,
      emailGSC,
      emailAdsense,
      hosting,
      topic,
      adsense,
      clickstar,
      footprints,
      addedToBWT,
      addedToManageWP,
      domainRenewalPrice,
      domainRenewalDate,
      contentStatus,
      language,
      priority
    } = formData;
  
    if (
      !url ||
      !emailGSC ||
      !emailAdsense ||
      !hosting ||
      !topic ||
      !adsense ||
      !clickstar ||
      !footprints ||
      !addedToBWT ||
      !addedToManageWP ||
      !contentStatus ||
      !language ||
      !priority ||
      domainRenewalPrice === 0 ||
      !domainRenewalDate
    ) {
      alert("Todos los campos son obligatorios excepto las notas.");
      return;
    }
  
    onAddWeb(formData);
    handleClose();
  };

  const handleClose = () => {
    setFormData({
      url: "",
      emailGSC: "",
      emailAdsense: "",
      hosting: "",
      topic: "",
      adsense: "",
      clickstar: "",
      footprints: "",
      addedToBWT: "",
      addedToManageWP: "",
      domainRenewalPrice: 0,
      domainRenewalDate: "",
      contentStatus: "Redactando",
      language: "",
      notes: "",
      priority: "media",
    });
    onClose();
  };

  const handleNewLanguageSave = () => {
    if (newLanguage && !customLanguages.includes(newLanguage)) {
      const updatedLanguages = [...customLanguages, newLanguage];
      setCustomLanguages(updatedLanguages);
      localStorage.setItem("customLanguages", JSON.stringify(updatedLanguages));
      setFormData({ ...formData, language: newLanguage });
      setNewLanguage("");
    }
  };

  useEffect(() => {
    if (hostingOptions && topicOptions) {
      setHostingOptions(hostingOptions);
      setTopicOptions(topicOptions);
    }
  }, [hostingOptions, topicOptions, setHostingOptions, setTopicOptions]);
  
  const handleNewHostingSave = () => {
    if (newHosting) {
      const updatedHostingOptions = [...hostingOptions, newHosting];
      setHostingOptions(updatedHostingOptions);
      setFormData({ ...formData, hosting: newHosting });
      setNewHosting("");
    }
  };

  const handleNewTopicSave = () => {
    if (newTopic) {
      const updatedTopicOptions = [...topicOptions, newTopic];
      setTopicOptions(updatedTopicOptions);
      setFormData({ ...formData, topic: newTopic });
      setNewTopic("");
    }
  };

  const handleDeleteHosting = (option) => {
    const updatedHostingOptions = hostingOptions.filter((item) => item !== option);
    setHostingOptions(updatedHostingOptions);
  };

  const handleDeleteTopic = (option) => {
    const updatedTopicOptions = topicOptions.filter((item) => item !== option);
    setTopicOptions(updatedTopicOptions);
  };

  const allLanguageOptions = [...languageOptions.filter(l => l !== 'new'), ...customLanguages, 'new'];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Añadir nueva web</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="URL de la Web"
          name="url"
          fullWidth
          variant="outlined"
          value={formData.url}
          onChange={handleChange}
          required
        />

        <TextField
          margin="dense"
          label="Email GSC"
          name="emailGSC"
          fullWidth
          variant="outlined"
          value={formData.emailGSC}
          onChange={handleChange}
          required
          InputProps={{
            startAdornment: (
              <EmailIcon color="action" sx={{ mr: 1 }} />
            ),
          }}
        />

        <TextField
          margin="dense"
          label="Email Adsense"
          name="emailAdsense"
          fullWidth
          variant="outlined"
          value={formData.emailAdsense}
          onChange={handleChange}
          required
          InputProps={{
            startAdornment: (
              <EmailIcon color="action" sx={{ mr: 1 }} />
            ),
          }}
        />

        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Estado del Contenido</InputLabel>
          <Select
            name="contentStatus"
            value={formData.contentStatus}
            onChange={handleChange}
          >
            <MenuItem value="Redactando" sx={{ backgroundColor: contentStatusColors.Redactando }}>
              Redactando
            </MenuItem>
            <MenuItem value="Completado" sx={{ backgroundColor: contentStatusColors.Completado }}>
              Completado
            </MenuItem>
            <MenuItem value="Pausado" sx={{ backgroundColor: contentStatusColors.Pausado }}>
              Pausado
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Adsense</InputLabel>
          <Select
            name="adsense"
            value={formData.adsense}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            <MenuItem value="Sin Enviar">Sin Enviar</MenuItem>
            <MenuItem value="Enviado">Enviado</MenuItem>
            <MenuItem value="Rechazado">Rechazado</MenuItem>
            <MenuItem value="Aprobado">Aprobado</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Clickstar</InputLabel>
          <Select
            name="clickstar"
            value={formData.clickstar}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            <MenuItem value="Sin Enviar">Sin Enviar</MenuItem>
            <MenuItem value="Enviado">Enviado</MenuItem>
            <MenuItem value="Rechazado">Rechazado</MenuItem>
            <MenuItem value="Pausado">Pausado</MenuItem>
            <MenuItem value="Aprobado">Aprobado</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Limpieza de Footprints</InputLabel>
          <Select
            name="footprints"
            value={formData.footprints}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            <MenuItem value="Sí">Sí</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Nicho</InputLabel>
          <Select
            name="nicho"
            value={formData.nicho}
            onChange={handleChange}
          >
            <MenuItem value="Sí">Sí</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>

        {/* Nuevo campo: Añadido a BWT */}
        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Añadido a BWT</InputLabel>
          <Select
            name="addedToBWT"
            value={formData.addedToBWT}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            <MenuItem value="Sí">Sí</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>

        {/* Nuevo campo: Añadido a ManageWP */}
        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Añadido a ManageWP</InputLabel>
          <Select
            name="addedToManageWP"
            value={formData.addedToManageWP}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            <MenuItem value="Sí">Sí</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Hosting</InputLabel>
          <Select
            name="hosting"
            value={formData.hosting}
            onChange={handleChange}
            required
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            {hostingOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
                <IconButton
                  onClick={() => handleDeleteHosting(option)}
                  size="small"
                  sx={{ marginLeft: 1 }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </MenuItem>
            ))}
            <MenuItem value="new">Añadir nuevo...</MenuItem>
          </Select>
        </FormControl>
        {formData.hosting === "new" && (
          <Box display="flex" mt={2}>
            <TextField
              label="Nuevo Hosting"
              value={newHosting}
              onChange={(e) => setNewHosting(e.target.value)}
              fullWidth
            />
            <Button onClick={handleNewHostingSave} sx={{ ml: 2 }}>
              Guardar
            </Button>
          </Box>
        )}

        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Temática</InputLabel>
          <Select
            name="topic"
            value={formData.topic}
            onChange={handleChange}
            required
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            {topicOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
                <IconButton
                  onClick={() => handleDeleteTopic(option)}
                  size="small"
                  sx={{ marginLeft: 1 }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </MenuItem>
            ))}
            <MenuItem value="new">Añadir nuevo...</MenuItem>
          </Select>
        </FormControl>
        {formData.topic === "new" && (
          <Box display="flex" mt={2}>
            <TextField
              label="Nueva Temática"
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              fullWidth
            />
            <Button onClick={handleNewTopicSave} sx={{ ml: 2 }}>
              Guardar
            </Button>
          </Box>
        )}

        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Idioma</InputLabel>
          <Select
            name="language"
            value={formData.language}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            {allLanguageOptions
              .filter(lang => lang !== 'new')
              .map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            <MenuItem value="new">Añadir nuevo...</MenuItem>
          </Select>
        </FormControl>
        {formData.language === "new" && (
          <Box display="flex" mt={2}>
            <TextField
              label="Nuevo Idioma"
              value={newLanguage}
              onChange={(e) => setNewLanguage(e.target.value)}
              fullWidth
            />
            <Button onClick={handleNewLanguageSave} sx={{ ml: 2 }}>
              Guardar
            </Button>
          </Box>
        )}

        <FormControl fullWidth style={{ marginTop: "10px" }} required>
          <InputLabel>Prioridad</InputLabel>
          <Select
            name="priority"
            value={formData.priority}
            onChange={handleChange}
          >
            <MenuItem value="alta">
              <Box sx={{ display: 'flex', alignItems: 'center', color: priorityColors.alta }}>
                <FlagIcon sx={{ mr: 1 }} />
                Alta
              </Box>
            </MenuItem>
            <MenuItem value="media">
              <Box sx={{ display: 'flex', alignItems: 'center', color: priorityColors.media }}>
                <FlagIcon sx={{ mr: 1 }} />
                Media
              </Box>
            </MenuItem>
            <MenuItem value="baja">
              <Box sx={{ display: 'flex', alignItems: 'center', color: priorityColors.baja }}>
                <FlagIcon sx={{ mr: 1 }} />
                Baja
              </Box>
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          margin="dense"
          label="Notas adicionales"
          name="notes"
          fullWidth
          variant="outlined"
          value={formData.notes}
          onChange={handleChange}
          multiline
          rows={4}
        />

        <TextField
          margin="dense"
          label="Precio de renovación"
          name="domainRenewalPrice"
          fullWidth
          type="number"
          variant="outlined"
          value={formData.domainRenewalPrice}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          label="Fecha de renovación"
          name="domainRenewalDate"
          type="date"
          fullWidth
          variant="outlined"
          value={formData.domainRenewalDate}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Añadir Web
        </Button>
      </DialogActions>
    </Dialog>
  );
}