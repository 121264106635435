// Lista de sitios web para simular datos
const sites = [
    'nicho1.com',
    'nicho2.com',
    'nicho3.com',
    'nicho4.com',
    'nicho5.com'
  ];
  
  // Función para generar un número aleatorio entre min y max
  const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
  
  // Función para generar datos aleatorios para un día y sitio específico
  const generateDayData = (site, date) => ({
    site,
    date,
    views: randomNumber(1000, 5000),
    clicks: randomNumber(50, 200),
    costs: Number((randomNumber(5, 30) + Math.random()).toFixed(2))
  });
  
  // Genera datos para los últimos 7 días para todos los sitios
  export const generateMockData = () => {
    const mockData = [];
    const today = new Date();
  
    sites.forEach(site => {
      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        mockData.push(generateDayData(site, date.toISOString().split('T')[0]));
      }
    });
  
    return mockData;
  };
  
  // Simular datos de AdSense
  export const generateAdSenseData = () => {
    const mockData = [];
    const today = new Date();
  
    sites.forEach(site => {
      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        mockData.push({
          site,
          date: date.toISOString().split('T')[0],
          impressions: randomNumber(800, 4000),
          clicks: randomNumber(40, 150),
          earnings: Number((randomNumber(3, 25) + Math.random()).toFixed(2))
        });
      }
    });
  
    return mockData;
  };