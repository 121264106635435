import React, { useState } from 'react';
import {
  Card, 
  CardContent, 
  CardHeader, 
  Typography, 
  Select, 
  MenuItem,
  FormControl, 
  TextField, 
  Checkbox, 
  Button, 
  Box
} from "@mui/material";
import FlagIcon from '@mui/icons-material/Flag';
import EmailIcon from '@mui/icons-material/Email';

// Colores para los estados de contenido
const contentStatusColors = {
  'Redactando': '#FFF3CD',
  'Completado': '#D4EDDA',
  'Pausado': '#FFE5D0'
};

// Colores para las prioridades
const priorityColors = {
  'alta': '#F44336',    // Rojo
  'media': '#FFC107',   // Amarillo
  'baja': '#4CAF50'     // Verde
};

// Lista de idiomas predefinidos
const languageOptions = [
  'Español',
  'Inglés',
  'Francés',
  'Alemán',
  'Italiano',
  'Portugués',
  'Japonés',
  'Chino'
];

// Función para convertir fecha de formato DD/MM/YYYY a objeto Date
function parseDate(dateString) {
  if (!dateString) return null;
  
  if (dateString instanceof Date) return dateString;
  
  if (dateString.includes('-')) {
    return new Date(dateString);
  }
  
  const [day, month, year] = dateString.split('/');
  if (day && month && year) {
    return new Date(year, month - 1, day);
  }
  
  return null;
}

function formatDate(date) {
  if (!date) return 'Fecha no válida';
  
  if (typeof date === 'string') {
    date = parseDate(date);
  }
  
  if (!(date instanceof Date) || isNaN(date)) return 'Fecha no válida';
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function toISODate(date) {
  if (!date) return '';
  
  if (typeof date === 'string') {
    date = parseDate(date);
  }
  
  if (!(date instanceof Date) || isNaN(date)) return '';
  
  return date.toISOString().split('T')[0];
}

const badgeStyle = (value) => {
  if (value in contentStatusColors) {
    return {
      backgroundColor: contentStatusColors[value],
      color: '#666666',
      padding: "4px 8px",
      borderRadius: "12px",
      fontSize: "12px",
    };
  }
  
  return {
    backgroundColor: 
      value === "Sí" || value === "Aprobado" ? "#A8D5BA" :
      value === "No" || value === "Rechazado" ? "#F28B82" :
      value === "Pausado" ? "#A8CCE2" :
      value === "Sin Enviar" ? "#666666" :
      value === "Enviado" ? "#FF6C2C" : "#FCE79E",
    color: "white",
    padding: "4px 8px",
    borderRadius: "12px",
    fontSize: "12px",
  };
};

export default function WebsiteCard({ 
  site, 
  selectedWebsites = [], 
  setSelectedWebsites, 
  setWebsites, 
  hostingOptions, 
  topicOptions 
}) {
  const [editingWebId, setEditingWebId] = useState(null);
  const [editedHosting, setEditedHosting] = useState(site.hosting);
  const [editedTopic, setEditedTopic] = useState(site.topic);
  const [editedAdsense, setEditedAdsense] = useState(site.adsense);
  const [editedClickstar, setEditedClickstar] = useState(site.clickstar);
  const [editedFootprints, setEditedFootprints] = useState(site.footprints);
  const [editedNicho, setEditedNicho] = useState(site.nicho || 'No'); // Aseguramos valor por defecto
  const [editedAddedToBWT, setEditedAddedToBWT] = useState(site.addedToBWT || 'No');
  const [editedAddedToManageWP, setEditedAddedToManageWP] = useState(site.addedToManageWP || 'No');
  const [editedContentStatus, setEditedContentStatus] = useState(site.contentStatus || 'Redactando');
  const [editedLanguage, setEditedLanguage] = useState(site.language || '');
  const [editedNotes, setEditedNotes] = useState(site.notes);
  const [editedPriority, setEditedPriority] = useState(site.priority);
  const [editedEmailGSC, setEditedEmailGSC] = useState(site.emailGSC || '');
  const [editedEmailAdsense, setEditedEmailAdsense] = useState(site.emailAdsense || '');

  const handleEdit = (id) => {
    setEditingWebId(id);
    // Inicializar todos los estados de edición con los valores actuales
    setEditedHosting(site.hosting);
    setEditedTopic(site.topic);
    setEditedAdsense(site.adsense);
    setEditedClickstar(site.clickstar);
    setEditedFootprints(site.footprints);
    setEditedNicho(site.nicho || 'No');
    setEditedAddedToBWT(site.addedToBWT || 'No');
    setEditedAddedToManageWP(site.addedToManageWP || 'No');
    setEditedContentStatus(site.contentStatus || 'Redactando');
    setEditedLanguage(site.language || '');
    setEditedNotes(site.notes);
    setEditedPriority(site.priority);
    setEditedEmailGSC(site.emailGSC || '');
    setEditedEmailAdsense(site.emailAdsense || '');
  };

  const handleInputChange = (id, field, value) => {
    setWebsites((prevWebsites) =>
      prevWebsites.map((website) => {
        if (website.id === id) {
          if (field === "domainRenewalDate") {
            const dateValue = parseDate(value);
            return {
              ...website,
              [field]: dateValue ? dateValue.toISOString() : value
            };
          }
          return { ...website, [field]: value };
        }
        return website;
      })
    );
  };

  const handleSave = (id) => {
    handleInputChange(id, "hosting", editedHosting);
    handleInputChange(id, "topic", editedTopic);
    handleInputChange(id, "adsense", editedAdsense);
    handleInputChange(id, "clickstar", editedClickstar);
    handleInputChange(id, "footprints", editedFootprints);
    handleInputChange(id, "nicho", editedNicho); // Guardar el valor de nicho
    handleInputChange(id, "addedToBWT", editedAddedToBWT);
    handleInputChange(id, "addedToManageWP", editedAddedToManageWP);
    handleInputChange(id, "contentStatus", editedContentStatus);
    handleInputChange(id, "language", editedLanguage);
    handleInputChange(id, "notes", editedNotes);
    handleInputChange(id, "priority", editedPriority);
    handleInputChange(id, "emailGSC", editedEmailGSC);
    handleInputChange(id, "emailAdsense", editedEmailAdsense);
    setEditingWebId(null);
  };

  const handleDelete = (id) => {
    setWebsites((prevWebsites) => 
      prevWebsites.filter((website) => website.id !== id)
    );
  };
  const handleSelectWebsite = (id) => {
    setSelectedWebsites((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  return (
    <Card
      variant="outlined"
      style={{
        padding: "16px",
        borderRadius: "12px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Checkbox
          checked={selectedWebsites.includes(site.id)}
          onChange={() => handleSelectWebsite(site.id)}
        />
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleDelete(site.id)}
        >
          Eliminar
        </Button>
      </Box>

      <CardHeader
        title={site.url}
        subheader={editingWebId === site.id ? (
          <TextField
            type="date"
            value={toISODate(site.domainRenewalDate)}
            onChange={(e) => handleInputChange(site.id, "domainRenewalDate", e.target.value)}
            fullWidth
          />
        ) : (
          `Expira el: ${formatDate(site.domainRenewalDate)}`
        )}
      />

      <CardContent>
        {/* Email GSC */}
        <Typography variant="body2" style={{ fontWeight: "bold" }}>Email GSC:</Typography>
        {editingWebId === site.id ? (
          <TextField
            value={editedEmailGSC}
            onChange={(e) => setEditedEmailGSC(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: <EmailIcon color="action" sx={{ mr: 1 }} />,
            }}
          />
        ) : (
          <Typography variant="body2">{site.emailGSC}</Typography>
        )}

        {/* Email Adsense */}
        <Typography variant="body2" style={{ fontWeight: "bold" }}>Email Adsense:</Typography>
        {editingWebId === site.id ? (
          <TextField
            value={editedEmailAdsense}
            onChange={(e) => setEditedEmailAdsense(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: <EmailIcon color="action" sx={{ mr: 1 }} />,
            }}
          />
        ) : (
          <Typography variant="body2">{site.emailAdsense}</Typography>
        )}

      {/* Añadido a BWT */}
      <Typography variant="body2" style={{ fontWeight: "bold" }}>Añadido a BWT:</Typography>
      {editingWebId === site.id ? (
        <FormControl fullWidth>
          <Select
            value={editedAddedToBWT}
            onChange={(e) => setEditedAddedToBWT(e.target.value)}
          >
            <MenuItem value="Sí">Sí</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <Typography variant="body2" style={badgeStyle(site.addedToBWT || 'No')}>
          {site.addedToBWT || 'No'}
        </Typography>
      )}

      {/* Añadido a ManageWP */}
      <Typography variant="body2" style={{ fontWeight: "bold" }}>Añadido a ManageWP:</Typography>
      {editingWebId === site.id ? (
        <FormControl fullWidth>
          <Select
            value={editedAddedToManageWP}
            onChange={(e) => setEditedAddedToManageWP(e.target.value)}
          >
            <MenuItem value="Sí">Sí</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <Typography variant="body2" style={badgeStyle(site.addedToManageWP || 'No')}>
          {site.addedToManageWP || 'No'}
        </Typography>
      )}
        <Typography variant="body2" style={{ fontWeight: "bold" }}>Nicho:</Typography>
        {editingWebId === site.id ? (
          <FormControl fullWidth>
            <Select
              value={editedNicho}
              onChange={(e) => setEditedNicho(e.target.value)}
            >
              <MenuItem value="Sí">Sí</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <Typography variant="body2" style={badgeStyle(site.nicho || 'No')}>
            {site.nicho || 'No'}
          </Typography>
        )}

        {/* Estado del Contenido */}
        <Typography variant="body2" style={{ fontWeight: "bold", marginTop: "8px" }}>
          Estado del Contenido:
        </Typography>
        {editingWebId === site.id ? (
          <FormControl fullWidth>
            <Select
              value={editedContentStatus}
              onChange={(e) => setEditedContentStatus(e.target.value)}
              sx={{ marginBottom: 1 }}
            >
              <MenuItem value="Redactando" sx={{ backgroundColor: contentStatusColors.Redactando }}>
                Redactando
              </MenuItem>
              <MenuItem value="Completado" sx={{ backgroundColor: contentStatusColors.Completado }}>
                Completado
              </MenuItem>
              <MenuItem value="Pausado" sx={{ backgroundColor: contentStatusColors.Pausado }}>
                Pausado
              </MenuItem>
            </Select>
          </FormControl>
        ) : (
          <Typography variant="body2" style={badgeStyle(site.contentStatus || 'Redactando')}>
            {site.contentStatus || 'Redactando'}
          </Typography>
        )}

        {/* Adsense */}
        <Typography variant="body2" style={{ fontWeight: "bold" }}>Adsense:</Typography>
        {editingWebId === site.id ? (
          <FormControl fullWidth>
            <Select
              value={editedAdsense}
              onChange={(e) => setEditedAdsense(e.target.value)}
            >
              <MenuItem value="Enviado" sx={{ backgroundColor: "#FF6C2C", color: "white" }}>Enviado</MenuItem>
              <MenuItem value="Rechazado" sx={{ backgroundColor: "#F28B82", color: "white" }}>Rechazado</MenuItem>
              <MenuItem value="Aprobado" sx={{ backgroundColor: "#A8D5BA", color: "white" }}>Aprobado</MenuItem>
              <MenuItem value="Sin Enviar" sx={{ backgroundColor: "#666666", color: "white" }}>Sin Enviar</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <Typography variant="body2" style={badgeStyle(site.adsense)}>
            {site.adsense}
          </Typography>
        )}

        {/* Clickstar */}
        <Typography variant="body2" style={{ fontWeight: "bold" }}>Clickstar:</Typography>
        {editingWebId === site.id ? (
          <FormControl fullWidth>
            <Select
              value={editedClickstar}
              onChange={(e) => setEditedClickstar(e.target.value)}
            >
              <MenuItem value="Enviado" sx={{ backgroundColor: "#FF6C2C", color: "white" }}>Enviado</MenuItem>
              <MenuItem value="Rechazado" sx={{ backgroundColor: "#F28B82", color: "white" }}>Rechazado</MenuItem>
              <MenuItem value="Pausado" sx={{ backgroundColor: "#A8CCE2", color: "white" }}>Pausado</MenuItem>
              <MenuItem value="Aprobado" sx={{ backgroundColor: "#A8D5BA", color: "white" }}>Aprobado</MenuItem>
              <MenuItem value="Sin Enviar" sx={{ backgroundColor: "#666666", color: "white" }}>Sin Enviar</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <Typography variant="body2" style={badgeStyle(site.clickstar)}>
            {site.clickstar}
          </Typography>
        )}

        {/* Limpieza de Footprints */}
        <Typography variant="body2" style={{ fontWeight: "bold" }}>Limpieza de Footprints:</Typography>
        {editingWebId === site.id ? (
          <FormControl fullWidth>
            <Select
              value={editedFootprints}
              onChange={(e) => setEditedFootprints(e.target.value)}
            >
              <MenuItem value="Sí">Sí</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <Typography variant="body2" style={badgeStyle(site.footprints)}>
            {site.footprints}
          </Typography>
        )}

        <Typography variant="body2" style={{ fontWeight: "bold" }}>Hosting:</Typography>
        {editingWebId === site.id ? (
          <FormControl fullWidth>
            <Select
              value={editedHosting}
              onChange={(e) => setEditedHosting(e.target.value)}
            >
              {hostingOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Typography variant="body2">{site.hosting}</Typography>
        )}

        {/* Idioma */}
        <Typography variant="body2" style={{ fontWeight: "bold" }}>Idioma:</Typography>
        {editingWebId === site.id ? (
          <FormControl fullWidth>
            <Select
              value={editedLanguage}
              onChange={(e) => setEditedLanguage(e.target.value)}
            >
              {languageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Typography variant="body2">{site.language}</Typography>
        )}

        {/* Fecha de Añadido */}
        <Typography variant="body2" style={{ fontWeight: "bold", marginTop: "8px" }}>
          Fecha de añadido:
        </Typography>
        <Typography variant="body2">
          {formatDate(site.addedDate)}
        </Typography>

        {/* Prioridad */}
        <Typography variant="body2" style={{ fontWeight: "bold" }}>Prioridad:</Typography>
        {editingWebId === site.id ? (
          <FormControl fullWidth>
            <Select
              value={editedPriority}
              onChange={(e) => setEditedPriority(e.target.value)}
                >
                  <MenuItem value="alta">
                    <Box sx={{ display: 'flex', alignItems: 'center', color: priorityColors.alta }}>
                      <FlagIcon sx={{ mr: 1 }} />
                      Alta
                    </Box>
                  </MenuItem>
                  <MenuItem value="media">
                    <Box sx={{ display: 'flex', alignItems: 'center', color: priorityColors.media }}>
                      <FlagIcon sx={{ mr: 1 }} />
                      Media
                    </Box>
                  </MenuItem>
                  <MenuItem value="baja">
                    <Box sx={{ display: 'flex', alignItems: 'center', color: priorityColors.baja }}>
                      <FlagIcon sx={{ mr: 1 }} />
                      Baja
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FlagIcon 
                  sx={{ 
                    mr: 1, 
                    color: priorityColors[site.priority || 'media']
                  }} 
                />
                <Typography variant="body2" sx={{ color: priorityColors[site.priority || 'media'] }}>
                  {site.priority ? site.priority.charAt(0).toUpperCase() + site.priority.slice(1) : 'Media'}
                </Typography>
              </Box>
            )}
    
            {/* Notas */}
            <Typography variant="body2" style={{ fontWeight: "bold" }}>Notas:</Typography>
            {editingWebId === site.id ? (
              <TextField
                value={editedNotes}
                onChange={(e) => setEditedNotes(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />
            ) : (
              <Typography 
                variant="body2" 
                sx={{ 
                  whiteSpace: 'pre-wrap',
                  bgcolor: '#f5f5f5',
                  p: 1,
                  borderRadius: 1,
                  minHeight: '2em'
                }}
              >
                {site.notes || 'Sin notas'}
              </Typography>
            )}
    
            <Typography variant="body2" style={{ fontWeight: "bold" }}>Temática:</Typography>
            {editingWebId === site.id ? (
              <FormControl fullWidth>
                <Select
                  value={editedTopic}
                  onChange={(e) => setEditedTopic(e.target.value)}
                >
                  {topicOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="body2">{site.topic}</Typography>
            )}
    
            <Typography variant="body2" style={{ fontWeight: "bold" }}>Precio de renovación:</Typography>
            {editingWebId === site.id ? (
              <TextField
                value={site.domainRenewalPrice}
                onChange={(e) => handleInputChange(site.id, "domainRenewalPrice", e.target.value)}
                fullWidth
              />
            ) : (
              <Typography variant="body2">{site.domainRenewalPrice}€</Typography>
            )}
    
         <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
          {editingWebId === site.id ? (
            <Button variant="outlined" onClick={() => handleSave(site.id)}>
              Guardar
            </Button>
          ) : (
            <Button variant="outlined" onClick={() => handleEdit(site.id)}>
              Editar
            </Button>
          )}

              <Button variant="outlined" href={`https://${site.url}/wp-admin`} target="_blank">
                Admin
              </Button>
              <Button variant="outlined" href={`https://${site.url}`} target="_blank" sx={{ backgroundColor: "#A8D5E2", color: "white" }}>
              Web
              </Button>
            </Box>
          </CardContent>
        </Card>
      );
    }