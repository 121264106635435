// src/components/NotificationSnackbar.js

import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const CustomSnackbar = styled(Snackbar)({
  '& .MuiSnackbarContent-root': {
    backgroundColor: '#4CAF50', // Verde para el snackbar
    fontSize: '1.2rem', // Tamaño de fuente aumentado
    '@media (max-width: 600px)': {
      fontSize: '1rem', // Ajuste para móviles
    },
    '@media (max-width: 900px)': {
      fontSize: '1.1rem', // Ajuste para tablets
    },
  },
});

export default function NotificationSnackbar({ message, open, handleClose }) {
  const action = (
    <React.Fragment>
      <IconButton size="small" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <CustomSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={action}
    />
  );
}
