import { API_CONFIG } from '../config/apiConfig';
import { generateMockData } from '../utils/mockData';

class ClickstarService {
  constructor() {
    this.apiKey = API_CONFIG.clickstar.apiKey;
    this.baseUrl = 'https://clickstar.me/api';
    this.useMockData = true; // Cambiar a false cuando tengamos el endpoint real
  }

  /**
   * Formatea una fecha al formato requerido por la API de Clickstar (dd.mm.yyyy)
   * @param {Date} date 
   * @returns {string}
   */
  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  /**
   * Obtiene datos reales de la API de Clickstar
   * @param {Date} fromDate 
   * @param {Date} toDate 
   * @returns {Promise<Array>}
   */
  async getRealData(fromDate, toDate) {
    try {
      const from = this.formatDate(fromDate);
      const to = this.formatDate(toDate);
      
      const url = `${this.baseUrl}/stats/dsp/day?key=${this.apiKey}&from=${from}&to=${to}`;
      
      console.log('Fetching Clickstar data:', url);
      
      const response = await fetch(url);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Clickstar API error response:', errorText);
        throw new Error(`Error en la API de Clickstar: ${response.statusText}`);
      }

      const data = await response.json();
      return this.processRealData(data);
    } catch (error) {
      console.error('Error getting real data:', error);
      throw error;
    }
  }

  /**
   * Procesa los datos reales de la API y los agrupa por sitio
   * @param {Array} data 
   * @returns {Array}
   */
  processRealData(data) {
    // Cuando tengamos el endpoint real, ajustar este procesamiento
    return data.map(item => ({
      ...item,
      site: 'default.com', // Temporal hasta tener el endpoint por sitio
      views: parseInt(item.views) || 0,
      clicks: parseInt(item.clicks) || 0,
      costs: parseFloat(item.costs) || 0
    }));
  }

  /**
   * Obtiene datos simulados o reales según la configuración
   * @param {Date} fromDate 
   * @param {Date} toDate 
   * @returns {Promise<Array>}
   */
  async getStats(fromDate = new Date(), toDate = new Date()) {
    if (this.useMockData) {
      console.log('Using mock data for Clickstar');
      return generateMockData();
    }
    return this.getRealData(fromDate, toDate);
  }

  /**
   * Obtiene las estadísticas de los últimos 7 días
   * @returns {Promise<Array>}
   */
  async getLast7DaysStats() {
    try {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 6);
      
      const data = await this.getStats(startDate, endDate);
      
      // Ordenar los datos por fecha de más reciente a más antigua
      return data.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });
    } catch (error) {
      console.error('Error getting last 7 days stats:', error);
      throw error;
    }
  }

  /**
   * Obtiene estadísticas agrupadas por sitio web
   * @returns {Promise<Array>}
   */
  async getStatsBySite() {
    try {
      const data = await this.getLast7DaysStats();
      
      // Agrupar datos por sitio
      const groupedData = data.reduce((acc, item) => {
        const siteKey = item.site;
        if (!acc[siteKey]) {
          acc[siteKey] = {
            site: siteKey,
            totalViews: 0,
            totalClicks: 0,
            totalCosts: 0,
            dailyData: []
          };
        }

        acc[siteKey].totalViews += item.views;
        acc[siteKey].totalClicks += item.clicks;
        acc[siteKey].totalCosts += item.costs;
        acc[siteKey].dailyData.push({
          date: item.date,
          views: item.views,
          clicks: item.clicks,
          costs: item.costs
        });

        return acc;
      }, {});

      return Object.values(groupedData);
    } catch (error) {
      console.error('Error getting stats by site:', error);
      throw error;
    }
  }

  /**
   * Verifica el estado de la API
   * @returns {Promise<boolean>}
   */
  async checkApiStatus() {
    if (this.useMockData) return true;
    
    try {
      const today = new Date();
      const response = await this.getRealData(today, today);
      return Array.isArray(response) && response.length > 0;
    } catch (error) {
      console.error('Error checking Clickstar API status:', error);
      return false;
    }
  }
}

export const clickstarService = new ClickstarService();