import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { clickstarService } from '../services/clickstarService';
import { adsenseService } from '../services/adsenseService';

const IncomeContext = createContext();

export function IncomeProvider({ children }) {
  const [summary, setSummary] = useState(null);
  const [websiteIncomes, setWebsiteIncomes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const dataFetched = useRef(false);

  const processClickstarData = (rawData) => {
    if (!rawData || !Array.isArray(rawData)) {
      throw new Error('Datos de Clickstar inválidos');
    }

    // Agrupar datos por sitio web
    const websiteGrouped = rawData.reduce((acc, item) => {
      const { site } = item;
      if (!acc[site]) {
        acc[site] = {
          web: site,
          clickstar: 0,
          adsense: 0,
          totalAccumulated: 0,
          dailyData: [],
          trend: 0
        };
      }

      const costs = parseFloat(item.costs) || 0;
      acc[site].clickstar += costs;
      acc[site].totalAccumulated += costs;
      acc[site].dailyData.push({
        date: item.date,
        clickstar: costs,
        views: parseInt(item.views) || 0,
        clicks: parseInt(item.clicks) || 0
      });

      return acc;
    }, {});

    return websiteGrouped;
  };

  const processAdsenseData = async () => {
    try {
        const savedWebsites = JSON.parse(localStorage.getItem("websites")) || [];
        const websitesByEmail = savedWebsites.reduce((acc, web) => {
            if (web.emailAdsense && web.adsense === 'Aprobado') {
                if (!acc[web.emailAdsense]) {
                    acc[web.emailAdsense] = [];
                }
                acc[web.emailAdsense].push(web);
            }
            return acc;
        }, {});

        const accountsResponse = await fetch('http://localhost:3001/api/adsense/accounts');
        const accounts = await accountsResponse.json();

        const websiteGrouped = {};

        for (const account of accounts) {
            try {
                const response = await fetch(`http://localhost:3001/api/adsense/income/${account.id}`);
                const incomeData = await response.json();

                if (incomeData.success && incomeData.data) {
                    incomeData.data.forEach(siteData => {
                        const site = siteData.site;
                        
                        if (!websiteGrouped[site]) {
                            websiteGrouped[site] = {
                                web: site,
                                adsense: 0,
                                pageViews: 0,
                                impressions: 0,
                                clicks: 0,
                                ctr: 0,
                                cpc: 0,
                                rpm: 0,
                                dailyData: [],
                                emailAdsense: account.email,
                                accountId: account.id
                            };
                        }

                        // Acumulamos los valores
                        websiteGrouped[site].adsense += siteData.ingresos;
                        websiteGrouped[site].pageViews += siteData.pageViews;
                        websiteGrouped[site].impressions += siteData.impresiones;
                        websiteGrouped[site].clicks += siteData.clicks;
                        websiteGrouped[site].ctr = siteData.ctr;
                        websiteGrouped[site].cpc = siteData.cpc;
                        websiteGrouped[site].rpm = siteData.rpm;
                    });
                }
            } catch (error) {
                console.error(`Error obteniendo datos para cuenta ${account.id}:`, error);
            }
        }

        return websiteGrouped;
    } catch (error) {
        console.error('Error procesando datos de AdSense:', error);
        return {};
    }
};

  const mergeIncomeData = (clickstarData, adsenseData) => {
    const mergedData = { ...clickstarData };

    // Fusionar datos de AdSense
    Object.entries(adsenseData).forEach(([site, data]) => {
      if (!mergedData[site]) {
        mergedData[site] = {
          web: site,
          clickstar: 0,
          adsense: data.adsense,
          totalAccumulated: data.adsense,
          dailyData: data.dailyData,
          trend: 0
        };
      } else {
        mergedData[site].adsense = data.adsense;
        mergedData[site].totalAccumulated += data.adsense;
        
        // Fusionar datos diarios
        data.dailyData.forEach(adsenseDay => {
          const existingDay = mergedData[site].dailyData.find(
            day => day.date === adsenseDay.date
          );
          
          if (existingDay) {
            existingDay.adsense = adsenseDay.adsense;
            existingDay.views = (existingDay.views || 0) + adsenseDay.views;
            existingDay.clicks = (existingDay.clicks || 0) + adsenseDay.clicks;
          } else {
            mergedData[site].dailyData.push(adsenseDay);
          }
        });
      }
    });

    // Calcular tendencias y preparar datos finales
    return Object.values(mergedData).map(site => {
      // Ordenar datos diarios por fecha
      const sortedDailyData = site.dailyData.sort((a, b) => 
        new Date(b.date) - new Date(a.date)
      );

      // Calcular tendencia
      const trend = calculateTrend(sortedDailyData);

      return {
        ...site,
        trend,
        status: site.totalAccumulated > 0 ? 'Rentable' : 'No Rentable'
      };
    });
  };

  const calculateTrend = (dailyData) => {
    if (dailyData.length < 2) return 0;

    const today = (dailyData[0].clickstar || 0) + (dailyData[0].adsense || 0);
    const yesterday = (dailyData[1].clickstar || 0) + (dailyData[1].adsense || 0);

    if (yesterday === 0) return today > 0 ? 100 : 0;
    
    return ((today - yesterday) / yesterday) * 100;
  };

  const calculateSummary = (websiteData) => {
    const totals = websiteData.reduce((acc, site) => ({
      clickstarTotal: acc.clickstarTotal + site.clickstar,
      adsenseTotal: acc.adsenseTotal + site.adsense,
      totalCount: acc.totalCount + 1,
      totalAccumulated: acc.totalAccumulated + site.totalAccumulated
    }), { clickstarTotal: 0, adsenseTotal: 0, totalCount: 0, totalAccumulated: 0 });

    // Calcular tendencia general
    const overallTrend = websiteData.reduce((acc, site) => acc + site.trend, 0) / websiteData.length;

    return {
      totalLastWeek: totals.totalAccumulated,
      clickstarTotal: totals.clickstarTotal,
      adsenseTotal: totals.adsenseTotal,
      averagePerWeb: totals.totalCount > 0 ? totals.totalAccumulated / totals.totalCount : 0,
      weeklyTrend: overallTrend
    };
  };

  const loadData = async () => {
    if (dataFetched.current) return;
    
    try {
      setLoading(true);
      
      // Obtener datos de Clickstar
      const clickstarData = await clickstarService.getLast7DaysStats();
      const processedClickstarData = processClickstarData(clickstarData);
      
      // Obtener datos de AdSense
      const processedAdsenseData = await processAdsenseData();
      
      // Fusionar datos
      const mergedData = mergeIncomeData(processedClickstarData, processedAdsenseData);
      
      // Calcular resumen
      const summaryData = calculateSummary(mergedData);
      
      setWebsiteIncomes(mergedData);
      setSummary(summaryData);
      setLastUpdate(new Date());
      dataFetched.current = true;

      return true;
    } catch (err) {
      console.error('Error cargando datos:', err);
      setError(err.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const refreshData = async () => {
    try {
      setLoading(true);
      dataFetched.current = false;
      await loadData();
    } catch (error) {
      console.error('Error actualizando datos:', error);
      setError('Error actualizando datos: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    
    // Actualizar datos cada 5 minutos
    const interval = setInterval(() => {
      dataFetched.current = false;
      loadData();
    }, 5 * 60 * 1000);
    
    return () => {
      clearInterval(interval);
      dataFetched.current = false;
    };
  }, []);

  const value = {
    summary,
    websiteIncomes,
    loading,
    error,
    lastUpdate,
    refreshData
  };

  return (
    <IncomeContext.Provider value={value}>
      {children}
    </IncomeContext.Provider>
  );
}

export const useIncome = () => {
  const context = useContext(IncomeContext);
  if (!context) {
    throw new Error('useIncome debe usarse dentro de un IncomeProvider');
  }
  return context;
};