import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PanelDeNichos from "./components/PanelDeNichos";
import AddWebModal from "./components/AddWebModal";
import Header from "./components/Header";
import NotificationSnackbar from "./components/NotificationSnackbar";
import IncomesDashboard from "./components/IncomesDashboard";
import ApiConfig from "./components/ApiConfig";
import ConfirmDialog from './components/ConfirmDialog';
import Login from './components/auth/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { IncomeProvider } from './contexts/IncomeContext';
import { AuthProvider } from './contexts/AuthContext';
import { exportWebDataToExcel, importWebDataFromExcel } from "./components/dataHandler";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const AppContent = () => {
  const [currentView, setCurrentView] = useState('inicio');
  const [websites, setWebsites] = useState(() => {
    try {
      const savedWebsites = localStorage.getItem("websites");
      if (!savedWebsites || savedWebsites === "undefined") {
        return [];
      }
      const parsed = JSON.parse(savedWebsites);
      return Array.isArray(parsed) ? parsed : [];
    } catch (error) {
      console.error('Error loading websites from localStorage:', error);
      localStorage.removeItem("websites"); // Limpiar datos corruptos
      return [];
    }
  });

  // Estados para los filtros de BWT, ManageWP y Nicho
  const [addedToBWTFilter, setAddedToBWTFilter] = useState('');
  const [addedToManageWPFilter, setAddedToManageWPFilter] = useState('');
  const [nichoFilter, setNichoFilter] = useState('');

  // Estados existentes
  const [emailGSCOptions, setEmailGSCOptions] = useState([]);
  const [emailAdsenseOptions, setEmailAdsenseOptions] = useState([]);
  const [filter, setFilter] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedWebsites, setSelectedWebsites] = useState([]);
  const [filteredWebsites, setFilteredWebsites] = useState([]);
  const [adsenseFilter, setAdsenseFilter] = useState('');
  const [clickstarFilter, setClickstarFilter] = useState('');
  const [footprintsFilter, setFootprintsFilter] = useState('');
  const [hostingFilter, setHostingFilter] = useState('');
  const [topicFilter, setTopicFilter] = useState('');
  const [contentStatusFilter, setContentStatusFilter] = useState('');
  const [expirationOrder, setExpirationOrder] = useState('');
  const [languageFilter, setLanguageFilter] = useState(() => {
    const saved = localStorage.getItem("languageFilter");
    return saved && saved !== "undefined" ? JSON.parse(saved) : '';
  });
  const [priorityFilter, setPriorityFilter] = useState(() => {
    const saved = localStorage.getItem("priorityFilter");
    return saved && saved !== "undefined" ? JSON.parse(saved) : '';
  });
  const [addedDateOrder, setAddedDateOrder] = useState(() => {
    const saved = localStorage.getItem("addedDateOrder");
    return saved && saved !== "undefined" ? JSON.parse(saved) : '';
  });
  const [emailGSCFilter, setEmailGSCFilter] = useState('');
  const [emailAdsenseFilter, setEmailAdsenseFilter] = useState('');
  const [expirationDateRange, setExpirationDateRange] = useState({
    start: '',
    end: ''
  });
  const [addedDateRange, setAddedDateRange] = useState({
    start: '',
    end: ''
  });

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState(null);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const [confirmDialogTitle, setConfirmDialogTitle] = useState("");
  const [hostingOptions, setHostingOptions] = useState(() => {
    const savedHostingOptions = localStorage.getItem("hostingOptions");
    return savedHostingOptions && savedHostingOptions !== "undefined" 
      ? JSON.parse(savedHostingOptions) 
      : ["SEONICO", "INFOCANDO", "YOROKOBI"];
  });
  const [topicOptions, setTopicOptions] = useState(() => {
    const savedTopicOptions = localStorage.getItem("topicOptions");
    return savedTopicOptions && savedTopicOptions !== "undefined" 
      ? JSON.parse(savedTopicOptions) 
      : ["Idiomas", "Tecnología", "Salud"];
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const fileInputRef = useRef();

  useEffect(() => {
    const gscEmails = [...new Set(websites.map(web => web.emailGSC).filter(Boolean))];
    const adsenseEmails = [...new Set(websites.map(web => web.emailAdsense).filter(Boolean))];
    setEmailGSCOptions(gscEmails.sort());
    setEmailAdsenseOptions(adsenseEmails.sort());
  }, [websites]);

  // Función para calcular el total de renovación de todas las webs
  const calculateTotalRenewal = (webs) => {
    return webs.reduce((sum, web) => {
      const price = parseFloat(web.domainRenewalPrice) || 0;
      return sum + price;
    }, 0).toFixed(2);
  };

  // Nueva función para calcular el total de renovación solo de webs marcadas como nicho
  const calculateTotalNichoRenewal = (webs) => {
    return webs.reduce((sum, web) => {
      if (web.nicho === 'Sí') {
        const price = parseFloat(web.domainRenewalPrice) || 0;
        return sum + price;
      }
      return sum;
    }, 0).toFixed(2);
  };

  // Función existente modificada para calcular renovación del mes actual
  const calculateCurrentMonthRenewal = (webs) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return webs.reduce((sum, web) => {
      if (web.domainRenewalDate) {
        const renewalDate = new Date(web.domainRenewalDate);
        if (renewalDate.getMonth() === currentMonth && 
            renewalDate.getFullYear() === currentYear) {
          return sum + (parseFloat(web.domainRenewalPrice) || 0);
        }
      }
      return sum;
    }, 0).toFixed(2);
  };

  // Nueva función para calcular renovación del mes actual solo de webs marcadas como nicho
  const calculateCurrentMonthNichoRenewal = (webs) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return webs.reduce((sum, web) => {
      if (web.domainRenewalDate && web.nicho === 'Sí') {
        const renewalDate = new Date(web.domainRenewalDate);
        if (renewalDate.getMonth() === currentMonth && 
            renewalDate.getFullYear() === currentYear) {
          return sum + (parseFloat(web.domainRenewalPrice) || 0);
        }
      }
      return sum;
    }, 0).toFixed(2);
  };

  const getCurrentMonthName = () => {
    const months = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    return months[new Date().getMonth()];
  };

  const compareDates = (dateA, dateB, field = 'domainRenewalDate') => {
    const a = dateA?.[field] ? new Date(dateA[field]).getTime() : 0;
    const b = dateB?.[field] ? new Date(dateB[field]).getTime() : 0;
    return a - b;
  };

  const isDateInRange = (date, range) => {
    if (!date || (!range.start && !range.end)) return true;
    const checkDate = new Date(date);
    if (range.start && new Date(range.start) > checkDate) return false;
    if (range.end && new Date(range.end) < checkDate) return false;
    return true;
  };

  // Calcular todos los valores necesarios para las tarjetas informativas
  const totalRenewalCost = calculateTotalRenewal(filteredWebsites);
  const totalNichoRenewalCost = calculateTotalNichoRenewal(filteredWebsites);
  const currentMonthRenewalCost = calculateCurrentMonthRenewal(filteredWebsites);
  const currentMonthNichoRenewalCost = calculateCurrentMonthNichoRenewal(filteredWebsites);
  const currentMonthName = getCurrentMonthName();

  useEffect(() => {
    const result = websites.filter((site) => {
      const matchesFilter = site.url?.toLowerCase().includes(filter.toLowerCase());
      const matchesAdsense = adsenseFilter ? site.adsense === adsenseFilter : true;
      const matchesClickstar = clickstarFilter ? site.clickstar === clickstarFilter : true;
      const matchesNicho = nichoFilter ? site.nicho === nichoFilter : true;
      const matchesFootprints = footprintsFilter ? site.footprints === footprintsFilter : true;
      const matchesHosting = hostingFilter ? site.hosting === hostingFilter : true;
      const matchesTopic = topicFilter ? site.topic === topicFilter : true;
      const matchesContentStatus = contentStatusFilter ? site.contentStatus === contentStatusFilter : true;
      const matchesLanguage = languageFilter ? site.language === languageFilter : true;
      const matchesPriority = priorityFilter ? site.priority === priorityFilter : true;
      const matchesEmailGSC = emailGSCFilter ? site.emailGSC === emailGSCFilter : true;
      const matchesEmailAdsense = emailAdsenseFilter ? site.emailAdsense === emailAdsenseFilter : true;
      const matchesAddedToBWT = addedToBWTFilter ? site.addedToBWT === addedToBWTFilter : true;
      const matchesAddedToManageWP = addedToManageWPFilter ? site.addedToManageWP === addedToManageWPFilter : true;
      const matchesExpirationRange = isDateInRange(site.domainRenewalDate, expirationDateRange);
      const matchesAddedRange = isDateInRange(site.addedDate, addedDateRange);

      return (
        matchesFilter &&
        matchesAdsense &&
        matchesClickstar &&
        matchesNicho &&
        matchesFootprints &&
        matchesHosting &&
        matchesTopic &&
        matchesContentStatus &&
        matchesLanguage &&
        matchesPriority &&
        matchesEmailGSC &&
        matchesEmailAdsense &&
        matchesAddedToBWT &&
        matchesAddedToManageWP &&
        matchesExpirationRange &&
        matchesAddedRange
      );
    });

    let sortedResult = [...result];
    
    if (expirationOrder) {
      sortedResult.sort((a, b) => {
        const comparison = compareDates(a, b, 'domainRenewalDate');
        return expirationOrder === 'asc' ? comparison : -comparison;
      });
    }
    
    if (addedDateOrder) {
      sortedResult.sort((a, b) => {
        const comparison = compareDates(a, b, 'addedDate');
        return addedDateOrder === 'asc' ? comparison : -comparison;
      });
    }

    setFilteredWebsites(sortedResult);
  }, [
    websites, 
    filter, 
    adsenseFilter, 
    clickstarFilter, 
    footprintsFilter, 
    hostingFilter, 
    topicFilter, 
    contentStatusFilter, 
    expirationOrder,
    languageFilter, 
    priorityFilter, 
    addedDateOrder, 
    emailGSCFilter,
    emailAdsenseFilter, 
    expirationDateRange, 
    addedDateRange,
    addedToBWTFilter,
    addedToManageWPFilter,
    nichoFilter
  ]);

  useEffect(() => {
    localStorage.setItem("websites", JSON.stringify(websites));
  }, [websites]);

  useEffect(() => {
    localStorage.setItem("hostingOptions", JSON.stringify(hostingOptions));
  }, [hostingOptions]);

  useEffect(() => {
    localStorage.setItem("topicOptions", JSON.stringify(topicOptions));
  }, [topicOptions]);

  useEffect(() => {
    localStorage.setItem("languageFilter", JSON.stringify(languageFilter));
    localStorage.setItem("priorityFilter", JSON.stringify(priorityFilter));
    localStorage.setItem("addedDateOrder", JSON.stringify(addedDateOrder));
  }, [languageFilter, priorityFilter, addedDateOrder]);

  const selectAllWidgets = () => {
    setSelectedWebsites(filteredWebsites.map((web) => web.id));
  };

  const clearSelection = () => {
    setSelectedWebsites([]);
  };

  const resetFilters = () => {
    setFilter("");
    setAdsenseFilter("");
    setClickstarFilter("");
    setFootprintsFilter("");
    setHostingFilter("");
    setTopicFilter("");
    setContentStatusFilter("");
    setExpirationOrder("");
    setLanguageFilter("");
    setPriorityFilter("");
    setAddedDateOrder("");
    setEmailGSCFilter("");
    setEmailAdsenseFilter("");
    setAddedToBWTFilter("");
    setAddedToManageWPFilter("");
    setNichoFilter("");
    setExpirationDateRange({ start: '', end: '' });
    setAddedDateRange({ start: '', end: '' });
    setFilteredWebsites(websites);
  };

  const handleConfirmDelete = () => {
    const updatedWebsites = websites.filter((web) => !selectedWebsites.includes(web.id));
    setWebsites(updatedWebsites);
    setSnackbarMessage(`Se eliminaron ${selectedWebsites.length} webs.`);
    setSnackbarOpen(true);
    clearSelection();
    resetFilters();
    setConfirmDialogOpen(false);

    if (updatedWebsites.length === 0) {
      setHostingOptions([]);
      setTopicOptions([]);
    }
  };

  const deleteSelectedWidgets = () => {
    setConfirmDialogTitle("Confirmar eliminación");
    setConfirmDialogMessage(`¿Estás seguro de que deseas eliminar ${selectedWebsites.length} web(s)?`);
    setConfirmDialogAction(() => handleConfirmDelete);
    setConfirmDialogOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleAddWeb = (newWeb) => {
    if (!websites.some((web) => web.url === newWeb.url)) {
      const webWithFormattedDate = {
        ...newWeb,
        id: Math.random().toString(36).substr(2, 9),
        contentStatus: newWeb.contentStatus || 'Redactando',
        domainRenewalDate: newWeb.domainRenewalDate ? new Date(newWeb.domainRenewalDate).toISOString() : null,
        addedDate: new Date().toISOString(),
        emailGSC: newWeb.emailGSC || '',
        emailAdsense: newWeb.emailAdsense || '',
        addedToBWT: newWeb.addedToBWT || 'No',
        addedToManageWP: newWeb.addedToManageWP || 'No',
        nicho: newWeb.nicho || 'No'
      };
      
      setWebsites(prevWebsites => [webWithFormattedDate, ...prevWebsites]);
      
      if (newWeb.hosting && !hostingOptions.includes(newWeb.hosting)) {
        setHostingOptions([...hostingOptions, newWeb.hosting]);
      }
      if (newWeb.topic && !topicOptions.includes(newWeb.topic)) {
        setTopicOptions([...topicOptions, newWeb.topic]);
      }
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setConfirmDialogAction(null);
  };

  const handleExport = () => {
    exportWebDataToExcel(websites);
  };

  const handleImport = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const importedData = await importWebDataFromExcel(
          file,
          websites.map((web) => web.url),
          {
            current: hostingOptions,
            set: (newOptions) => {
              setHostingOptions(prevOptions => {
                const mergedOptions = [...new Set([...prevOptions, ...newOptions])];
                localStorage.setItem("hostingOptions", JSON.stringify(mergedOptions));
                return mergedOptions;
              });
            }
          },
          {
            current: topicOptions,
            set: (newOptions) => {
              setTopicOptions(prevOptions => {
                const mergedOptions = [...new Set([...prevOptions, ...newOptions])];
                localStorage.setItem("topicOptions", JSON.stringify(mergedOptions));
                return mergedOptions;
              });
            },
          }
        );
        
        setWebsites((prevWebsites) => {
          const uniqueWebsites = [...prevWebsites];
          importedData.forEach((data) => {
            if (!prevWebsites.some((web) => web.url === data.url)) {
              uniqueWebsites.push({
                ...data,
                domainRenewalDate: data.domainRenewalDate ? new Date(data.domainRenewalDate).toISOString() : null,
                contentStatus: data.contentStatus || 'Redactando',
                addedDate: data.addedDate || new Date().toISOString(),
                emailGSC: data.emailGSC || '',
                emailAdsense: data.emailAdsense || '',
                addedToBWT: data.addedToBWT || 'No',
                addedToManageWP: data.addedToManageWP || 'No',
                nicho: data.nicho || 'No'
              });
            }
          });
          return uniqueWebsites;
        });

        fileInputRef.current.value = "";
        
      } catch (error) {
        console.error('Error al importar el archivo:', error);
        setSnackbarMessage("Error al importar el archivo");
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <div className="App">
      <IncomeProvider>
        <Header
          filter={filter}
          setFilter={setFilter}
          adsenseFilter={adsenseFilter}
          setAdsenseFilter={setAdsenseFilter}
          clickstarFilter={clickstarFilter}
          setClickstarFilter={setClickstarFilter}
          nichoFilter={nichoFilter}
          setNichoFilter={setNichoFilter}
          footprintsFilter={footprintsFilter}
          setFootprintsFilter={setFootprintsFilter}
          hostingFilter={hostingFilter}
          setHostingFilter={setHostingFilter}
          topicFilter={topicFilter}
          setTopicFilter={setTopicFilter}
          contentStatusFilter={contentStatusFilter}
          setContentStatusFilter={setContentStatusFilter}
          expirationOrder={expirationOrder}
          setExpirationOrder={setExpirationOrder}
          languageFilter={languageFilter}
          setLanguageFilter={setLanguageFilter}
          priorityFilter={priorityFilter}
          setPriorityFilter={setPriorityFilter}
          addedDateOrder={addedDateOrder}
          setAddedDateOrder={setAddedDateOrder}
          emailGSCFilter={emailGSCFilter}
          setEmailGSCFilter={setEmailGSCFilter}
          emailAdsenseFilter={emailAdsenseFilter}
          setEmailAdsenseFilter={setEmailAdsenseFilter}
          addedToBWTFilter={addedToBWTFilter}
          setAddedToBWTFilter={setAddedToBWTFilter}
          addedToManageWPFilter={addedToManageWPFilter}
          setAddedToManageWPFilter={setAddedToManageWPFilter}
          emailGSCOptions={emailGSCOptions}
          emailAdsenseOptions={emailAdsenseOptions}
          expirationDateRange={expirationDateRange}
          setExpirationDateRange={setExpirationDateRange}
          addedDateRange={addedDateRange}
          setAddedDateRange={setAddedDateRange}
          hostingOptions={hostingOptions}
          topicOptions={topicOptions}
          onAddWebClick={handleOpenModal}
          selectAllWidgets={selectAllWidgets}
          clearSelection={clearSelection}
          deleteSelectedWidgets={deleteSelectedWidgets}
          onExport={handleExport}
          onImport={handleImport}
          fileInputRef={fileInputRef}
          totalWebs={websites.length}
          filteredWebs={filteredWebsites.length}
          totalRenewalCost={totalRenewalCost}
          totalNichoRenewalCost={totalNichoRenewalCost}
          currentMonthRenewalCost={currentMonthRenewalCost}
          currentMonthNichoRenewalCost={currentMonthNichoRenewalCost}
          currentMonthName={currentMonthName}
          activeMenu={currentView}
          onMenuClick={setCurrentView}
        />

        <main>
          {currentView === 'inicio' && (
            <PanelDeNichos
              websites={filteredWebsites}
              setWebsites={setWebsites}
              onOpenModal={handleOpenModal}
              selectedWebsites={selectedWebsites}
              setSelectedWebsites={setSelectedWebsites}
              hostingOptions={hostingOptions}
              setHostingOptions={setHostingOptions}
              topicOptions={topicOptions}
              setTopicOptions={setTopicOptions}
            />
          )}

          {currentView === 'ingresos' && (
            <IncomesDashboard />
          )}

          {currentView === 'api-config' && (
            <ApiConfig />
          )}
        </main>

        <AddWebModal
          open={modalOpen}
          onClose={handleCloseModal}
          onAddWeb={handleAddWeb}
          hostingOptions={hostingOptions}
          setHostingOptions={setHostingOptions}
          topicOptions={topicOptions}
          setTopicOptions={setTopicOptions}
        />

        <NotificationSnackbar
          message={snackbarMessage}
          open={snackbarOpen}
          handleClose={handleCloseSnackbar}
        />

        <ConfirmDialog
          open={confirmDialogOpen}
          onClose={handleCloseConfirmDialog}
          onConfirm={confirmDialogAction}
          title={confirmDialogTitle}
          message={confirmDialogMessage}
        />
      </IncomeProvider>
    </div>
  );
};

// Componente principal que maneja la autenticación y rutas
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            {/* Ruta pública */}
            <Route path="/login" element={<Login />} />
            
            {/* Ruta por defecto */}
            <Route 
              path="/" 
              element={<Navigate to="/panel" replace />} 
            />
            
            {/* Rutas protegidas */}
            <Route
              path="/panel/*"
              element={
                <ProtectedRoute>
                  <AppContent />
                </ProtectedRoute>
              }
            />
            
            {/* Redirigir /dashboard a /panel */}
            <Route 
              path="/dashboard/*" 
              element={<Navigate to="/panel" replace />} 
            />

            {/* Ruta catch-all */}
            <Route path="*" element={<Navigate to="/panel" replace />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;