import React, { useState, useEffect } from "react";
import { Typography, Tooltip, Fab, Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import WebsiteCard from './WebsiteCard';

export default function PanelDeNichos({
  websites,
  setWebsites,
  onOpenModal,
  selectedWebsites,
  setSelectedWebsites,
  hostingOptions,
  topicOptions,
}) {
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    const allSelected = websites.length > 0 && websites.every(site => selectedWebsites.includes(site.id));
    setAllSelected(allSelected);
  }, [websites, selectedWebsites]);

  const handleSelectAll = () => {
    const websiteIds = websites.map(site => site.id);
    setSelectedWebsites(websiteIds);
  };

  const handleDeleteSelected = () => {
    const remainingWebsites = websites.filter(site => !selectedWebsites.includes(site.id));
    setWebsites(remainingWebsites);
    setSelectedWebsites([]);
  };

  return (
    <div style={{ padding: "20px", position: "relative", display: "flex", flexDirection: "column" }}>
      {/* Grilla de websites */}
      <div style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        gap: "16px",
        marginTop: "10px",
        flex: 1,
      }}>
        {websites.length > 0 ? (
          websites.map((site) => (
            <WebsiteCard
              key={site.id}
              site={site}
              selectedWebsites={selectedWebsites || []}
              setSelectedWebsites={setSelectedWebsites}
              setWebsites={setWebsites}
              hostingOptions={hostingOptions}
              topicOptions={topicOptions}
            />
          ))
        ) : (
          <div>No se encontraron webs</div>
        )}
      </div>

      {/* Botón para añadir webs */}
      <Tooltip title="Añadir Web" arrow>
        <Fab
          color="primary"
          aria-label="add"
          onClick={onOpenModal}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            backgroundColor: "#a8d5e2",
          }}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  );
}