import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Stack
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  Search as SearchIcon,
  MonetizationOn as MonetizationOnIcon,
  Timeline as TimelineIcon,
  Speed as SpeedIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  ShowChart as ShowChartIcon,
  Download as DownloadIcon,
  CalendarToday as CalendarIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';

// Estilos
const DashboardContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f8f9fa',
  minHeight: '100vh',
  '& .MuiGrid-container': {
    margin: 0,
    width: '100%'
  }
}));



const MetricsGrid = styled(Grid)(({ theme }) => ({
  margin: `${theme.spacing(3)} 0`,
  '& .MuiGrid-item': {
    width: '100%'
  }
}));

// Actualización de estilos para las tarjetas de métricas
const MetricCard = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(3),
  borderRadius: 0,
  border: '1px solid #e0e0e0',
  minHeight: '140px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#fafafa'
  }
}));

const MetricTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
  textTransform: 'uppercase',
  letterSpacing: '0.5px',
  marginBottom: theme.spacing(2)
}));

// Estilo de texto para métricas
const MetricValue = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
}));

const MetricSubtext = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5)
}));

const TableSection = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  border: '1px solid #e0e0e0',
  borderRadius: 0,
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  '& .MuiTableHead-root': {
    backgroundColor: '#f8f9fa',
    '& .MuiTableCell-head': {
      fontWeight: 600,
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      letterSpacing: '0.5px',
      color: theme.palette.text.secondary,
      borderBottom: '2px solid #e0e0e0',
      whiteSpace: 'nowrap',
      padding: theme.spacing(2)
    }
  },
  '& .MuiTableBody-root .MuiTableRow-root': {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04)
    },
    '& .MuiTableCell-body': {
      fontSize: '0.875rem',
      borderBottom: '1px solid #e0e0e0',
      padding: theme.spacing(2)
    }
  }
}));


const FiltersBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}));

const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    height: 40,
    backgroundColor: '#fff',
    '& fieldset': {
      borderColor: '#e0e0e0'
    },
    '&:hover fieldset': {
      borderColor: '#bdbdbd'
    }
  }
}));

const MetricLabel = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));



// Nuevo estilo para el contenedor de filtros
const FiltersContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  borderRadius: 0,
  border: '1px solid #e0e0e0',
  boxShadow: 'none',
  backgroundColor: '#ffffff',
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}));


const StatsCard = styled(Paper)(({ trend }) => ({
  padding: '24px',
  borderRadius: '10px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: trend > 0 ? '#4caf50' : trend < 0 ? '#f44336' : '#1976d2'
  }
}));

const TableWrapper = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  backgroundColor: '#ffffff',
  borderRadius: 0,
  border: '1px solid #e0e0e0',
  boxShadow: 'none',
  width: '100%',
  overflowX: 'auto',
  
  '& .MuiTable-root': {
    borderCollapse: 'collapse'
  },

  '& .MuiTableHead-root': {
    backgroundColor: '#f5f5f5',
    '& .MuiTableCell-root': {
      fontWeight: 500,
      color: theme.palette.text.primary,
      borderBottom: '1px solid #e0e0e0',
      padding: '12px 16px',
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      letterSpacing: '0.5px'
    }
  },

  '& .MuiTableBody-root': {
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: '#f5f5f5'
      },
      '& .MuiTableCell-root': {
        padding: '12px 16px',
        fontSize: '0.875rem',
        borderBottom: '1px solid #e0e0e0'
      }
    }
  }
}));


const ChartContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '4px',
  border: '1px solid #e0e0e0',
  padding: theme.spacing(3),
  height: '400px',
  boxShadow: 'none'
}));

// Utilidades de formato
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  }).format(amount);
};

const formatNumber = (num) => {
  return new Intl.NumberFormat('es-ES').format(num);
};

const formatPercentage = (value) => {
  return `${Number(value).toFixed(2)}%`;
};

// Componente de Widget
const StatWidget = ({ title, value, subtitle, trend, icon: Icon }) => (
  <MetricCard>
    <Box>
      <Typography variant="caption" color="textSecondary" sx={{ textTransform: 'uppercase', letterSpacing: 0.5 }}>
        {title}
      </Typography>
      <Typography variant="h4" sx={{ my: 1, fontWeight: 500 }}>
        {value}
      </Typography>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {trend !== undefined && (
        <TrendIndicator trend={trend}>
          {trend > 0 ? <TrendingUpIcon fontSize="small" /> : <TrendingDownIcon fontSize="small" />}
          {Math.abs(trend)}%
        </TrendIndicator>
      )}
      <Typography variant="caption" color="textSecondary">
        {subtitle}
      </Typography>
    </Box>
  </MetricCard>
);

const TrendIndicator = styled(Typography)(({ trend, theme }) => ({
  fontSize: '0.75rem',
  color: trend > 0 ? theme.palette.success.main : theme.palette.error.main,
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
}));

const DashboardHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}));

const IncomesDashboard = () => {
  // Estados
  const [dateRange, setDateRange] = useState('yesterday');
  const [customDateRange, setCustomDateRange] = useState({ start: null, end: null });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'ingresos', direction: 'desc' });
  const [adsenseData, setAdsenseData] = useState([]);
  const [summary, setSummary] = useState({
    totalEarnings: 0,
    lastThirtyDays: 0,
    thisMonth: 0,
    lastMonth: 0,
    lastNinetyDays: 0,
    averageCTR: 0,
    averageRPM: 0,
    totalClicks: 0,
    totalImpressions: 0,
    totalPageViews: 0,
    validSites: 0,
    thirtyDaysTrend: 0
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [isCustomDateDirty, setIsCustomDateDirty] = useState(false);

  // Efectos
  useEffect(() => {
    if (!isCustomDateDirty) {
      loadAdsenseData();
    }
  }, [dateRange]);

  // Funciones auxiliares
  const loadAdsenseData = async () => {
    try {
      setLoading(true);
      setError(null);

      let params = new URLSearchParams();
      params.append('dateRange', dateRange);
      
      if (dateRange === 'custom' && customDateRange.start && customDateRange.end) {
        params.append('startDate', format(customDateRange.start, 'yyyy-MM-dd'));
        params.append('endDate', format(customDateRange.end, 'yyyy-MM-dd'));
      }

      const response = await fetch(`http://localhost:3001/api/adsense/income?${params}`);
      
      if (!response.ok) {
        throw new Error('Error al cargar datos de AdSense');
      }

      const result = await response.json();
      
      if (result.success) {
        setAdsenseData(result.data);
        setSummary(result.summary);
      } else {
        throw new Error(result.error || 'Error al procesar datos de AdSense');
      }

      setIsCustomDateDirty(false);
    } catch (error) {
      console.error('Error loading AdSense data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const loadHistoricalData = async () => {
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append('dateRange', dateRange);
      
      if (dateRange === 'custom' && customDateRange.start && customDateRange.end) {
        params.append('startDate', format(customDateRange.start, 'yyyy-MM-dd'));
        params.append('endDate', format(customDateRange.end, 'yyyy-MM-dd'));
      }

      const response = await fetch(
        `http://localhost:3001/api/adsense/income/${selectedWebsite.id}/historical?${params}`
      );

      if (!response.ok) {
        throw new Error('Error al cargar datos históricos');
      }

      const result = await response.json();
      
      if (result.success) {
        setChartData(result.data.map(item => ({
          date: format(new Date(item.date), 'dd/MM/yyyy'),
          ingresos: parseFloat(item.earnings),
          ctr: parseFloat(item.ctr)
        })));
      } else {
        throw new Error(result.error || 'Error al procesar datos históricos');
      }
    } catch (error) {
      console.error('Error loading historical data:', error);
      setError('Error al cargar datos históricos: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortedData = () => {
    if (!adsenseData.length) return [];

    const filtered = adsenseData.filter(site =>
      site.site.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return filtered.sort((a, b) => {
      const getValue = (item, key) => {
        if (item === null || item === undefined) return 0;
        switch (key) {
          case 'ingresos':
          case 'ctr':
          case 'cpc':
          case 'rpm':
            return parseFloat(item[key]) || 0;
          case 'clicks':
          case 'impresiones':
            return parseInt(item[key]) || 0;
          default:
            return item[key] || '';
        }
      };

      const aValue = getValue(a, sortConfig.key);
      const bValue = getValue(b, sortConfig.key);

      if (typeof aValue === 'string') {
        return sortConfig.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
    });
  };

  // Manejadores de eventos
  const handleDateRangeChange = (event) => {
    const newRange = event.target.value;
    setDateRange(newRange);
    if (newRange !== 'custom') {
      setCustomDateRange({ start: null, end: null });
      setIsCustomDateDirty(false);
    }
  };

  const handleApplyCustomDate = () => {
    if (customDateRange.start && customDateRange.end) {
      loadAdsenseData();
    }
  };

  const handleResetFilters = () => {
    setDateRange('yesterday');
    setCustomDateRange({ start: null, end: null });
    setIsCustomDateDirty(false);
    setSearchTerm('');
    loadAdsenseData();
  };

  const handleShowChart = async (website) => {
    setSelectedWebsite(website);
    setShowChart(true);
    await loadHistoricalData();
    handleMenuClose();
  };

  const handleExportData = async (website) => {
    try {
      let params = new URLSearchParams();
      params.append('dateRange', dateRange);
      
      if (dateRange === 'custom' && customDateRange.start && customDateRange.end) {
        params.append('startDate', format(customDateRange.start, 'yyyy-MM-dd'));
        params.append('endDate', format(customDateRange.end, 'yyyy-MM-dd'));
      }

      const response = await fetch(
        `http://localhost:3001/api/adsense/income/${website.id}/export?${params}`
      );

      if (!response.ok) {
        throw new Error('Error al exportar datos');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${website.site}_${dateRange}_datos.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data:', error);
      setError(error.message);
    }
    handleMenuClose();
  };

  const handleMenuOpen = (event, website) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedWebsite(website);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // Renderizado
  return (
    <DashboardContainer>
      <DashboardHeader>
        <Typography variant="h4" fontWeight="bold" color="text.primary">
          Dashboard de Ingresos
        </Typography>
        <SearchField
          size="medium"
          placeholder="Buscar sitio..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
      </DashboardHeader>

      <FiltersContainer>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Período</InputLabel>
          <Select
            value={dateRange}
            onChange={handleDateRangeChange}
            label="Período"
          >
            <MenuItem value="yesterday">Ayer</MenuItem>
            <MenuItem value="7days">Últimos 7 días</MenuItem>
            <MenuItem value="30days">Últimos 30 días</MenuItem>
            <MenuItem value="thisMonth">Este Mes</MenuItem>
            <MenuItem value="lastMonth">Mes Pasado</MenuItem>
            <MenuItem value="last90days">Últimos 90 días</MenuItem>
            <MenuItem value="custom">Personalizado</MenuItem>
          </Select>
        </FormControl>

        {dateRange === 'custom' && (
          <Stack direction="row" spacing={2} alignItems="center">
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <DatePicker
                label="Desde"
                value={customDateRange.start}
                onChange={(newValue) => {
                  setCustomDateRange(prev => ({...prev, start: newValue}));
                  setIsCustomDateDirty(true);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
              <DatePicker
                label="Hasta"
                value={customDateRange.end}
                onChange={(newValue) => {
                  setCustomDateRange(prev => ({...prev, end: newValue}));
                  setIsCustomDateDirty(true);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              onClick={handleApplyCustomDate}
              disabled={!customDateRange.start || !customDateRange.end || !isCustomDateDirty}
            >
              Aplicar
            </Button>
          </Stack>
        )}

        <Button
          variant="outlined"
          onClick={handleResetFilters}
          startIcon={<CalendarIcon />}
        >
          Resetear Filtros
        </Button>
      </FiltersContainer>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <StatWidget
            title="Ingresos Totales"
            value={formatCurrency(summary.totalEarnings)}
            subtitle={`${summary.validSites} sitios activos`}
            icon={MonetizationOnIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <StatWidget
            title="Últimos 30 días"
            value={formatCurrency(summary.lastThirtyDays)}
            subtitle="vs mes anterior"
            trend={parseFloat(summary.thirtyDaysTrend)}
            icon={TimelineIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <StatWidget
            title="Este Mes"
            value={formatCurrency(summary.thisMonth)}
            subtitle={`vs ${formatCurrency(summary.lastMonth)} mes anterior`}
            icon={MonetizationOnIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <StatWidget
            title="RPM Promedio"
            value={formatCurrency(summary.averageRPM)}
            subtitle={`${formatNumber(summary.totalPageViews)} páginas vistas`}
            icon={SpeedIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <StatWidget
            title="CTR Promedio"
            value={formatPercentage(summary.averageCTR)}
            subtitle={`${formatNumber(summary.totalClicks)} clicks de ${formatNumber(summary.totalImpressions)} impresiones`}
            icon={TimelineIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <StatWidget
            title="Últimos 90 días"
            value={formatCurrency(summary.lastNinetyDays)}
            subtitle={`Promedio diario: ${formatCurrency(summary.lastNinetyDays / 90)}`}
            icon={TimelineIcon}
          />
        </Grid>
      </Grid>

      <TableWrapper component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleSort('site')}>Sitio</TableCell>
              <TableCell onClick={() => handleSort('ingresos')}>Ingresos</TableCell>
              <TableCell onClick={() => handleSort('clicks')}>Clicks</TableCell>
              <TableCell onClick={() => handleSort('impresiones')}>Impresiones</TableCell>
              <TableCell onClick={() => handleSort('ctr')}>CTR</TableCell>
              <TableCell onClick={() => handleSort('cpc')}>CPC</TableCell>
              <TableCell onClick={() => handleSort('rpm')}>RPM</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSortedData().map((site) => (
              <TableRow key={site.site}>
                <TableCell>{site.site}</TableCell>
                <TableCell>{formatCurrency(site.ingresos)}</TableCell>
                <TableCell>{formatNumber(site.clicks)}</TableCell>
                <TableCell>{formatNumber(site.impresiones)}</TableCell>
                <TableCell>{formatPercentage(site.ctr)}</TableCell>
                <TableCell>{formatCurrency(site.cpc)}</TableCell>
                <TableCell>{formatCurrency(site.rpm)}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => handleMenuOpen(e, site)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleShowChart(selectedWebsite)}>
          <ListItemIcon>
            <ShowChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Ver gráfico</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleExportData(selectedWebsite)}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Exportar datos</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog
        open={showChart}
        onClose={() => setShowChart(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          Evolución de métricas - {selectedWebsite?.site}
        </DialogTitle>
        <DialogContent>
          {chartData.length > 0 ? (
            <ChartContainer>
              <ResponsiveContainer>
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis yAxisId="left" label={{ value: 'Ingresos (€)', angle: -90, position: 'insideLeft' }} />
                  <YAxis yAxisId="right" orientation="right" label={{ value: 'CTR (%)', angle: 90, position: 'insideRight' }} />
                  <RechartsTooltip
                    formatter={(value, name) => {
                      switch (name) {
                        case 'ingresos':
                          return [formatCurrency(value), 'Ingresos'];
                        case 'ctr':
                          return [formatPercentage(value), 'CTR'];
                        default:
                          return [value, name];
                      }
                    }}
                  />
                  <Legend />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="ingresos"
                    name="Ingresos"
                    stroke="#1976d2"
                    dot={false}
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="ctr"
                    name="CTR"
                    stroke="#4caf50"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartContainer>
          ) : (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowChart(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Box sx={{ mt: 2, p: 2, bgcolor: 'error.light', borderRadius: 1 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
    </DashboardContainer>
  );
};

export default IncomesDashboard;